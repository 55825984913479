import { useEffect } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import { persistor } from "../index";
import { LOGOUT } from "../store/reducers/auth";
import { deleteSchool } from "../store/reducers/schoolSlice";
import { getPlaylistLogout } from "../components/axios/customAxios";
  
const LogoutProcess = () => {
    console.log('====================== LogoutProcess =====================')
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    
    const logout = async () => {
        // console.log(222);
        // store에 저장된 Access Token 정보를 삭제
        dispatch(LOGOUT());
        dispatch(deleteSchool());
        //console.log(222);
    }

    //초기화 하는 함수
    const purge = async () => {
       console.log('purge')
        await persistor.purge();
        // navigate('/auth/connect');
        window.location.reload(true);
        window.location.replace('/auth/connect');
    }
    
    const onIdle = async() => {
        console.log('Idle');
        //setState('Idle')
        // setOpen(false)
        await logout()
        // await setTimeout(() => purge(), 200)
        setTimeout(async() => await purge(), 200)
    }

    const onActive = () => {
        console.log('Active');
        //setState('Active')
        // setOpen(false)
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
        // onPrompt,
        //timeout: process.env.REACT_APP_DEFAULT_LOGIN_EXPIRE_TIME * 1000,
        // timeout: expireOut,
        // promptBeforeIdle,
        throttle: 500
    })

    // useEffect(async() => {
    //     await logout()
    //         // await setTimeout(() => purge(), 200)
    //     setTimeout(async() => await purge(), 200)
    // })

    useEffect(() => {
        const loadData = async () => {
            // console.log(1111)
            getPlaylistLogout().then(async(res)=>{
                // console.log(res)
                await logout()
                // await setTimeout(() => purge(), 200)
                setTimeout(async() => await purge(), 200)
            })
        }
        loadData();
    })
    
}

export default LogoutProcess;