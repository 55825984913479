import { PURGE } from "redux-persist";
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    connectCode : 'NONE',
    name : '',
    // role : 'NONE',
};

const schoolSlice = createSlice({
    name : 'sch',
    initialState,
    reducers : {
        setSchool : (state, action) => {
            state.name = action.payload.schoolName;
            state.connectCode = action.payload.playlistConnectCode;
            // state.name = action.payload.name;
            // state.role = action.payload.role;
        },
        deleteSchool : (state) => {
            state.connectCode = 'NONE';
            state.name = '';
            // state.role = 'NONE';
        }
    },
    extraReducers : (builder) => {
        builder.addCase(PURGE, ()=> initialState);
    }
});

export const { setSchool, deleteSchool } = schoolSlice.actions;

export default schoolSlice.reducer;