import { useEffect, useState } from "react";
import { backHtmlspecialchars } from "../../data/func";
import { postContents } from "../axios/customAxios";
import ErrorPage from "../../pages/ErrorPage";

function WiseSaying({isLast=false, title, schoolCommonContentsSeqNo, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
        getWiseSaying();
    }, []);

    const [error, setError] = useState(null);
    const [contents, setContents] = useState(null);
    const [contents2, setContents2] = useState(null);
    function getWiseSaying() {
        postContents('/api/contents/school/common/FS/'+schoolCommonContentsSeqNo,{isAlert:!isLast}).then((res)=>{
        // postContents('/api/contents/school/common/FS',{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null && res.response.detail != null) {
                setContents(res.response.detail);
                let contents2 = backHtmlspecialchars(res.response.detail.contents2);
                if (contents2) {
                    contents2 = contents2.replace(/\r\n/ig, '<br>');
                    contents2 = contents2.replace(/\\n/ig, '<br>');
                    contents2 = contents2.replace(/\n/ig, '<br>');
                    if (contents2.indexOf("<br>") != -1) {
                        contents2 = contents2.replace(/\<br>/g, '<br/>');
                    }
                    let contents = contents2.split('<br/>');
                    setContents2(contents);
                } else {
                    setContents2(null);
                }
            } else {
                if (res?.error != null) {
                    setError(res?.error);
                }
                setContents(null);
            }
            
            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    if (contents != null) {
        return (
        // <div className="scene_saying animate__animated animate__bounceIn">
        <div className="scene_saying">
            <div>
                <div>
                    <div>
                        <p dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents1)}}></p>
                        <p style={{whiteSpace:"pre-wrap"}}>
                            {contents2 
                                && contents2.map((item, i)=>{
                                    return (<span key={i} dangerouslySetInnerHTML={{__html:item}}></span>)
                                })
                            }
                        </p>
                        <p dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents3)}}></p>
                    </div>
                </div>
            </div>
            <hr/>
        </div>
        )
    } else {
        if (error) {
            return (
            <ErrorPage title={"명언-"+title} error={error} />
            )
        }
    }
}

export default WiseSaying;