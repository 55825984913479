import PhotoAlbum from "react-photo-album";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id, width, height) => `https://source.unsplash.com/${id}/${width}x${height}`;

const unsplashPhotos = [
  { id: "Jztmx9yqjBw", width: 1200, height: 607 , title:"6767"},
  { id: "-heLWtuAN3c", width: 1200, height: 608 , title:"777"},
  { id: "xOigCUcFdA8", width: 1200, height: 720 , title:"888"},
  { id: "1azAjl8FTnU", width: 1200, height: 1549 , title:"999"},
  { id: "Yizrl9N_eDA", width: 1200, height: 721 , title:"444"},
  { id: "KG3TyFi0iTU", width: 1200, height: 1520 , title:"555"},
  { id: "ALrCdq-ui_Q", width: 1200, height: 720 , title:"000"},
  { id: "8gVv6nxq6gY", width: 1200, height: 700 , title:"111"},
  { id: "Dhmn6ete6g8", width: 1200, height: 1520 , title:"222"},
  { id: "RkBTPqPEGDo", width: 1200, height: 720 , title:"333"},

//   { id: "twukN12EN7c", width: 1080, height: 694 },
//   { id: "9UjEyzA6pP4", width: 1080, height: 1620 },
//   { id: "sEXGgun3ZiE", width: 1080, height: 720 },
//   { id: "S-cdwrx-YuQ", width: 1080, height: 1440 },
//   { id: "q-motCAvPBM", width: 1080, height: 1620 },
//   { id: "Xn4L310ztMU", width: 1080, height: 810 },
//   { id: "iMchCC-3_fE", width: 1080, height: 610 },
//   { id: "X48pUOPKf7A", width: 1080, height: 160 },
//   { id: "GbLS6YVXj0U", width: 1080, height: 810 },
//   { id: "9CRd1J1rEOM", width: 1080, height: 720 },
//   { id: "xKhtkhc9HbQ", width: 1080, height: 1440 },
];

// eslint-disable-next-line
var photos = unsplashPhotos.map((photo) => ({
  src: unsplashLink(photo.id, photo.width, photo.height),
  width: photo.width,
  height: photo.height,
  title:photo.title,
  srcSet: breakpoints.map((breakpoint) => {
    const height = Math.round((photo.height / photo.width) * breakpoint);
    return {
      src: unsplashLink(photo.id, breakpoint, height),
      width: breakpoint,
      height,
    };
  }),
}));

const renderPhoto = ({ layout, layoutOptions, imageProps: { alt, style, description, ...restImageProps } }) => (
    <div
      className="gallery_box"
      style={{
        width: style?.width,
        paddingBottom: 0,
      }}
    >
      <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
      <div
        className="gallery_cont"
      >
        <p>이미지 설명...이미지 설명...이미지 설명...이미지 설명...이미지 설명...이미지 설명...{restImageProps.title}...</p>
        {/* {Math.round(layout.width) + " x " + Math.round(layout.height)} */}
      </div>
    </div>
  );

export default function App() {
    return (
        <>
          <div className="scene_gallery_columns">
              <p className="gallery_tit">대한민국고등학교 학생 전시회 ‘우리들의 자서전’</p>
              <PhotoAlbum layout="columns" photos={photos} 
                  renderPhoto={renderPhoto}
                columns={(containerWidth) => {
                  if (containerWidth < 1920) return 4;
                  return 4;
              }}/>
              {/* <video>
                <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"></source>
              </video> */}
            </div>
        </>
);
}