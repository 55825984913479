import { Outlet } from "react-router";
import styles from '../Login.module.css';

export default function LoginLayout() {
    return (
        <div className={`${styles.did_admin} ${styles.did_login} ${styles.did_connect}`}>
            <div className={styles.did_connect_box}>
                <div className={`${styles.login_box} ${styles.connect_box}`}>
                    <div className={styles.login_top}></div>
                    <div className={styles.login_bottom}>
                        <h1>
                            <img src={`${process.env.PUBLIC_URL}/images/logo-sm.png`} />
                            <span>Smart Did 접속</span>
                        </h1>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </div>

        // <div className="did_admin did_login did_connect">
        //     <div className="did_connect_box">
        //         <div className="login_box connect_box">
        //             <div className="login_top"></div>
        //             <div className="login_bottom">
        //                 <h1>
        //                     <img src={`${process.env.PUBLIC_URL}/images/logo-sm.png`} />
        //                     <span>Smart Did 접속</span>
        //                 </h1>
        //                 <Outlet/>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );
}
