import { useEffect, useState } from "react";
import { backHtmlspecialchars } from "../../data/func";
import { postContents } from "../axios/customAxios";
import ErrorPage from "../../pages/ErrorPage";

function Image({isLast=false, individualContentsSeqNo, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
        getImage();
    }, [individualContentsSeqNo]);

    const [error, setError] = useState(null);
    const [contents, setContents] = useState(null);
    const [contents2, setContents2] = useState(null);
    const [contents3, setContents3] = useState(null);
    function getImage() {
        postContents('/api/contents/individual/'+individualContentsSeqNo,{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null && res.response.detail != null) {
                const content = res.response.detail;
                setContents(content);
                let contents2 = backHtmlspecialchars(content.contents2);
                if (contents2) {
                    contents2 = contents2.replace(/\r\n/ig, '<br>');
                    contents2 = contents2.replace(/\\n/ig, '<br>');
                    contents2 = contents2.replace(/\n/ig, '<br>');
                    if (contents2.indexOf("<br>") !== -1) {
                        contents2 = contents2.replace(/\<br>/g, '<br/>');
                    }
                    setContents2(contents2.split('<br/>'))
                } else {
                    setContents2(null);
                }
                

                let contents3 = backHtmlspecialchars(content.contents3);
                if (contents3) {
                    contents3 = contents3.replace(/\r\n/ig, '<br>');
                    contents3 = contents3.replace(/\\n/ig, '<br>');
                    contents3 = contents3.replace(/\n/ig, '<br>');
                    if (contents3.indexOf("<br>") !== -1) {
                        contents3 = contents3.replace(/\<br>/g, '<br/>');
                    }
                    setContents3(contents3.split('<br/>'))
                } else {
                    setContents3(null);
                }
            } else {
                if (res?.error != null) {
                    setError(res?.error);
                }
                setContents(null);
            }

            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    if (contents != null) {
        return (
        // <div className="scene_painting animate__animated animate__bounceIn">
        <div className="scene_painting">
            <div>
                <div className="paint_blur"></div>
                <div>
                    <div className="scene_painting_box">
                        <div className="scene_painting_cont">
                            <div>
                                <img src={`${process.env.REACT_APP_UPLOAD}${contents && contents.fileUrl}`} alt="" />
                            </div>
                            <div className="painting_ment">
                                <p dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents1)}}></p>
                                <p style={{whiteSpace:"pre-wrap"}}>
                                    {contents2 
                                        && contents2.map((item, i)=>{
                                            return (<><span key={i} dangerouslySetInnerHTML={{__html:item}}></span><br/></>)
                                        })
                                    }
                                </p>
                                <p style={{whiteSpace:"pre-wrap"}}>
                                    {contents3 
                                        && contents3.map((item, i)=>{
                                            return (<><span key={i} dangerouslySetInnerHTML={{__html:item}}></span><br/></>)
                                        })
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <style jsx="true">{`
                .scene_painting > div::before {
                    background:url(${process.env.REACT_APP_UPLOAD}${contents && contents.fileUrl}) no-repeat;
                    background-size:cover;
                } 
            `}</style>
        </div>
        )
    // } else {
    //     return (
    //         <ErrorPage title={"이미지-"+title} error={error} />
    //     )
    }
}

export default Image;