import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ModalsProvider from './components/modals/ModalsProvider';
import { store } from './store';

const queryClient = new QueryClient({
  defaultOptions : {
    queries : {
      refetchOnMount : false,
      refetchOnReconnect : false,
      refetchOnWindowFocus : false
    }
  }
});

export let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={ store }>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ModalsProvider>
              <App />
            </ModalsProvider>
          </BrowserRouter>
        </PersistGate>
      </ReduxProvider>
    </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register();
// serviceWorkerRegistration.unregister();
