import { useEffect, useState } from "react";
import { postContents } from "../axios/customAxios";
import ErrorPage from "../../pages/ErrorPage";
import { backHtmlspecialchars } from "../../data/func";
// import ReactPlayer from "react-player";

function MovieDesc({isLast=false, title, individualContentsSeqNo, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
        getMovie();
    }, [individualContentsSeqNo]);

    const [error, setError] = useState(null);
    const [contents, setContents] = useState(null);
    function getMovie() {
        postContents('/api/contents/individual/'+individualContentsSeqNo,{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null && res.response.detail != null) {
                const content = res.response.detail;
                setContents(content);
                setTimeout(() => {
                    try {
                        var videoElement = document.getElementById("vd");
                        videoElement.src = `${process.env.REACT_APP_UPLOAD}${content && content.fileUrl}`;
                        videoElement.muted = true;
                        var promise = document.querySelector('video').play();
                        if (promise !== undefined) {
                            promise.then(_ => {
                                // 자동 재생 시작!
                                if (content?.volume) {
                                    videoElement.volume = content?.volume/10;
                                } else {
                                    // videoElement.volume = 0.1;
                                    videoElement.volume = 0;
                                }
                                try {
                                    videoElement.muted = false;
                                } catch(e) {
                                    console.log(e)
                                }
                                if (videoElement.paused) {
                                    videoElement.muted = true;
                                    videoElement.play();
                                }
                            }).catch(error => {
                                console.log(error)
                              // 자동 재생이 막힘.
                              // "Play" 버튼을 노출해 유저가 재생을 시작할 수 있도록 한다
                            });
                        }
                    } catch(e) {
                        console.log(e);
                    }

                    // try {
                    //     const videoUrl = `${process.env.REACT_APP_UPLOAD}${content && content.fileUrl}`;
                    //     // console.log(videoUrl)
                    //     fetch(videoUrl, {
                    //         method: 'GET',
                    //         credentials: 'include',
                    //         headers: {
                    //           //'Cache-Control': 'public, max-age=86400', 
                    //           //cache: 'force-cache',
                    //         },
                    //     })
                    //     .then(response => {
                    //         if (!response.ok) {
                    //         throw new Error(`Network response was not ok: ${response.status}`);
                    //         }
                    //         return response.blob();
                    //     })
                    //     .then(blob => {
                    //         const videoElement = document.getElementById('vd');
                    //         const videoUrlObject = URL.createObjectURL(blob);
                    //         videoElement.src = videoUrlObject;
                    //         videoElement.muted = true;
                    //         var promise = document.querySelector('video').play();
                    //         if (promise !== undefined) {
                    //             promise.then(_ => {
                    //                 // 자동 재생 시작!
                    //                 if (content?.volume) {
                    //                     videoElement.volume = content?.volume/10;
                    //                 } else {
                    //                     videoElement.volume = 0.1;
                    //                     videoElement.volume = 0;
                    //                 }
                    //                 try {
                    //                     videoElement.muted = false;
                    //                 } catch(e) {
                    //                     console.log(e)
                    //                 }
                    //                 console.log(videoElement.paused)
                    //                 if (videoElement.paused) {
                    //                     videoElement.muted = true;
                    //                     videoElement.play();
                    //                 }
                    //             }).catch(error => {
                    //                 console.log(error)
                    //                 // 자동 재생이 막힘.
                    //                 // "Play" 버튼을 노출해 유저가 재생을 시작할 수 있도록 한다
                    //             });
                    //         }
                    //     })
                    //     .catch(error => {
                    //         console.error('Fetch error:', error);
                    //     });
                    // } catch(e) {
                    //     console.log(e)
                    // }
                }, 500);
            } else {
                if (res?.error != null) {
                    setError(res?.error);
                }
                setContents(null);
            }

            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    // useEffect(()=>{
	// 	setTimeout(() => {
	// 		setVdVolumn();
	// 	}, 1000);
    // }, []);

	// function setVdVolumn() {
    //     try {
    //         var vd = document.getElementById("vd");
    //         console.log(vd)
    //         console.log(contents)
    //         vd.src = `${process.env.REACT_APP_UPLOAD}${contents && contents.fileUrl}`;
    //         // vd.muted = true;
    //         // vd.play();
    //         // vd.muted = false;
    //         // vd.volume = 0.1;
    //     } catch(e) {
    //         console.log(e)
    //     }
	// }
    if (contents != null && contents.fileUrl != null) {
        return (
        // <div className="scene_movie_ment animate__animated animate__bounceIn">
        <div className="scene_movie_ment">
            <div>
                <div>
                    <div className="scene_movie_ment_box">
                        <div className="scene_movie_ment_cont">
                            <div>
                                <div>
                                    <video id="vd" autoPlay muted={true} style={{width:"100%",height:"100%"}}>
                                        Your browser does not support the video tag.
                                        {/* <source src={`${process.env.REACT_APP_UPLOAD}${contents && contents.fileUrl}`} type="video/mp4" /> */}
                                    </video>
                                    {/* <video id="video" autoPlay muted={true} width="500" src="https://assets.dsbackend.com/media/d7f74a0ac38442128d2a1275f3dc3c5d/dabc43cbf7bd49c8a61f2db3f393048c/1/1080p.mp4"></video> */}
                                </div>
                            </div>
                            {(contents.contents1 || contents.contents2 || contents.contents3) &&
                            <div className="movie_ment">
                                <p dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents1)}}></p>
                                <p dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents2)}}></p>
                                <p dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents3)}}></p>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
        </div>
        )
    // } else {
    //     return (
    //         <ErrorPage title={"동영상-"+title} error={error} />
    //     )
    }
}

export default MovieDesc;