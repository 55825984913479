
function ErrorPage({title, error}) {
console.log(error)
  return (
      <div className="scene_error">
		<div>
			<div>
				<p>{title || error?.code}</p>
				<p>
					{error?.message}
				</p>
			</div>
		</div>
		<hr/>
	</div>
  );
}

export default ErrorPage;
