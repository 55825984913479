import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
    authenticated: false,
    accessToken: null,
    refreshToken: null
    // authenticatedTime : null
};

export const tokenSlice = createSlice({
    name: 'authToken',
    initialState,
    reducers: {
        LOGIN: (state, action) => {
            state.authenticated = true;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        LOGOUT: (state) => {
            state.authenticated = false;
            state.accessToken = null;
            state.refreshToken = null;
        },
    },
    extraReducers : (builder) => {
        builder.addCase(PURGE, ()=> initialState);
    }
})

export const { LOGIN, LOGOUT } = tokenSlice.actions;

export default tokenSlice.reducer;