import { useEffect, useState } from "react";

import { postContents } from "../axios/customAxios";
import { getCafeteriaType } from "../../data/func";

function SchoolMeal({isLast=false, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
        getSchoolCafeteriaList();
    }, []);

    const [cafeteriaList, setCafeteriaList] = useState(null);
    const [lunch, setLunch] = useState(null);
    const [crawlingDate, setCrawlingDate] = useState(null);
    function getSchoolCafeteriaList() {
        postContents('/api/contents/school/cafeteria',{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null) {
                setCafeteriaList(res.response.list);
                if (res.response.list && res.response.list.length > 0) {
                    const lunch = res.response.list.find(item=>item.type==='LC');
                    // console.log(lunch)
                    setLunch(lunch);
                }
                setCrawlingDate(res.response.crawlingDate);
            } else {
                setCafeteriaList(null);
                setLunch(null);
                setCrawlingDate(null);
            }

            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    return (
        // <div className="scene_schoolmeal animate__animated animate__bounceIn">
        <div className="scene_schoolmeal">
            <div>
                <div className="topbar">
                    {lunch ?
                    <div className="kcal animate__animated animate__fadeIn" style={{animationDelay: "0.5s"}}>
                        <span>칼로리</span>
                        <span>{lunch.calorie}</span>
                    </div>
                    :
                    <div className="kcal">
                    </div>
                    }
                    <hr/>
    
                    <div className="schoolmeal_pic animate__animated animate__fadeIn" style={{animationDelay: "1s"}}>
                        <img src={`${process.env.PUBLIC_URL}/images/schoolmeal.png`} alt="식단사진" /> 
                    </div>
                    <hr/>
    
                    <div className="schoolmeal_tit animate__animated animate__fadeIn" style={{animationDelay: "1.5s"}}>
                        <span className="material-symbols-outlined">
                            restaurant
                        </span>
                        <span>오늘의</span>&nbsp;<span className="orange">식단</span>
                    </div>
                    <hr/>
                </div>
    
                <div className="schoolmeal_cont">
                    {lunch ?
                    <div className="schoolmeal_info">
                        <div className="animate__animated animate__fadeIn" style={{animationDelay: "2s"}}>
                            <span>메뉴 [{lunch ? getCafeteriaType(lunch.type) : ''}]</span>
                            <ul>
                                {lunch.dish && lunch.dish.split('<br/>').map((item, i)=>{
                                    return (<li key={i}>{item}</li>)
                                })}
                            </ul>
                        </div>
                        <hr/>
    
                        <div className="animate__animated animate__fadeIn" style={{animationDelay: "2.5s"}}>
                            <span>원산지</span>
                            <ul>
                                {lunch.origin && lunch.origin.split('<br/>').map((item, i)=>{
                                    if (i < 9)
                                    return (<li key={i}>{item}</li>)
                                })}
                            </ul>
                        </div>
                        <hr/>
    
                        <div className="animate__animated animate__fadeIn" style={{animationDelay: "3s"}}>
                            <span>영양소</span>
                            <ul>
                                {lunch.nutrient && lunch.nutrient.split('<br/>').map((item, i)=>{
                                    if (i < 9)
                                    return (<li key={i}>{item}</li>)
                                })}
                            </ul>
                        </div>
                    </div>
                    :
                    <div className="schoolmeal_info">
                        <div style={{color:"#fff", fontSize:"24px"}}>
                            오늘의 식단이 존재하지 않습니다.
                        </div>
                    </div>
                    }
                    <hr/>
    
                    <div className="allergy animate__animated animate__fadeIn" style={{animationDelay: "3.5s"}}>
                        <p>* 알레르기 정보</p>
                        ①난류 ②우유 ③메밀 ④땅콩 ⑤대두 ⑥밀 ⑦고등어 ⑧게 ⑨새우 ⑩돼지고기 ⑪복숭아 ⑫토마토 ⑬아황산류 ⑭호두 ⑮닭고기 ⑯쇠고기 ⑰오징어 ⑱조개류(굴,전복,홍합 포함) ⑲잣
                    </div>
                </div>
                <hr/>
            </div>
        </div>
    )
}

export default SchoolMeal;