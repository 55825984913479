import { useEffect, useState } from "react";
import { postContents } from "../axios/customAxios";
import ErrorPage from "../../pages/ErrorPage";

function ImageFull({isLast=false, title, individualContentsSeqNo, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
        getImage();
    }, [individualContentsSeqNo]);

    const [error, setError] = useState(null);
    const [contents, setContents] = useState(null);
    function getImage() {
        postContents('/api/contents/individual/'+individualContentsSeqNo,{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null && res.response.detail != null) {
                setContents(res.response.detail);
            } else {
                if (res?.error != null) {
                    setError(res?.error);
                }
                setContents(null);
            }

            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    if (contents != null) {
        return (
        // <div className="scene_album animate__animated animate__bounceIn">
        <div className="scene_album">
            <div>
                <ul>
                    <li>
                        <img src={`${process.env.REACT_APP_UPLOAD}${contents && contents.fileUrl}`} alt="" />
                    </li>
                </ul>
            </div>
            <hr/>
        </div>
        )
    // } else {
    //     return (
    //         <ErrorPage title={"이미지(전체)-"+title} error={error} />
    //     )
    }
}

export default ImageFull;