import { useEffect, useState } from "react";
import { backHtmlspecialchars } from "../../data/func";
import { postContents } from "../axios/customAxios";
import ErrorPage from "../../pages/ErrorPage";

function Info({isLast=false, title, individualContentsSeqNo, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
        getInformation();
    }, [individualContentsSeqNo]);

    const [error, setError] = useState(null);
    const [contents, setContents] = useState(null);
    const [contents3, setContents3] = useState(null);
    function getInformation() {
        postContents('/api/contents/individual/'+individualContentsSeqNo,{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null && res.response.detail != null) {
                setContents(res.response.detail);
                let contents3 = backHtmlspecialchars(res.response.detail.contents3);
                if (contents3) {
                    contents3 = contents3.replace(/\r\n/ig, '<br>');
                    contents3 = contents3.replace(/\\n/ig, '<br>');
                    contents3 = contents3.replace(/\n/ig, '<br>');
                    if (contents3.indexOf("<br>") != -1) {
                        contents3 = contents3.replace(/\<br>/g, '<br/>');
                    }
                    let contents = contents3.split('<br/>');
                    setContents3(contents)
                } else {
                    setContents3(null)
                }
            } else {
                if (res?.error != null) {
                    setError(res?.error);
                }
                setContents(null);
            }

            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    if (contents != null) {
        return (
        // <div className="scene_useinfo animate__animated animate__bounceIn">
        <div className="scene_useinfo">
            <div className="useinfo_user_bg">
                {contents && contents.fileUrl ?
                <img src={`${process.env.REACT_APP_UPLOAD}${contents && contents.fileUrl}`} alt="" />
                :
                <img src={`${process.env.PUBLIC_URL}/images/useinfo_user_bg.png`} alt=""/>
                }
            </div>
			<div className="useinfo_user_box">
                <div className="useinfo_tit">
                    <span>{contents.title}</span>
                    <span dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents1)}}></span>
                    <i dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents2)}}></i>
                </div>
                <hr/>

                <div className="useinfo_cont">
                    <div style={{whiteSpace:"pre-wrap"}}>
                    {contents3 
                        && contents3.map((item, i)=>{
                            if (item != null && item != '')
                            return (<p key={i} dangerouslySetInnerHTML={{__html:item}}></p>)
                        })
                    }
                    </div>
                </div>
            </div>
            <hr/>
        </div>
        )
    // } else {
    //     return (
    //         <ErrorPage title={"공지-"+title} error={error} />
    //     )
    }
}

export default Info;