import React, { useEffect, useState } from 'react';

import { getPlaylistSchedule } from '../components/axios/customAxios';
import SplitScreen1 from './SplitScreen1';
import { useSelector } from 'react-redux';
import GeneralAlert from '../components/alert/GeneralAlert';
import EmergencyAlert from '../components/alert/EmergencyAlert';
// import * as serviceWorker from "../service-worker";

const Dashboard = () => {
  const schoolInfo = useSelector(state => state.sch);

  const [isLoading, setIsLoading] = useState(false);
  const [elementList, setElementList] = useState(null);
  const [max, setMax] = useState(0);
  const [order, setOrder] = useState(0);
  const [isApiConnect, setIsApiConnect] = useState(false);

  // const DYNAMIC_INDIVIDUAL_CACHE_NAME = 'dynamic-individual-cache-1.1';
  // const DYNAMIC_SCHOOLCOMMON_CACHE_NAME = 'dynamic-schoolcommon-cache-1.1';
  // const STATIC_CACHE_NAME = 'static-cache-1.1';

  let timer = 0;
  let repeatCycle = null;
  let i = 0;

  useEffect(() => {
    // console.log(isLoading)
    if (!isLoading) {
      playlistSchedule();
    }
  }, [isLoading]);

  async function playlistSchedule() {
    await getPlaylistSchedule().then((res)=>{
      // console.log(res)
      if (res && res.error != null) {
        setElementList(null);
        setIsLoading(false);
        // initSchedule()
      } else {
        if (res && res.response && res.response.scheduleList &&  res.response.scheduleList.length > 0) {
          console.log('set schedule')
          let scheduleList = res.response.scheduleList;
          // setElementList(scheduleList);
          let scheduleList2 = [...scheduleList];
          // scheduleList.map((schedule, idx)=>{
          //   console.log(schedule)
          // })
          setElementList(scheduleList2);
          timer = scheduleList[0].displayTime;
          let max = scheduleList.length;
          setOrder(0);
          setMax(max)
          i = 0;

          const individualContentUrls = new Array();
          const schoolcomonContentUrls = new Array();
          const fileboxContentUrls = new Array();
          scheduleList.map((schedule, idx)=>{
            // console.log(schedule);
            if (schedule?.contentsList[0]?.individualContents !== null) {
              if(schedule?.contentsList[0]?.individualContents?.fileUrl !== null && schedule?.contentsList[0]?.individualContents?.fileUrl !== undefined) {
                if (process.env.REACT_APP_UPLOAD.startsWith('http')) {
                  individualContentUrls.push(process.env.REACT_APP_UPLOAD+schedule?.contentsList[0]?.individualContents?.fileUrl);
                } else {
                  individualContentUrls.push(process.env.REACT_APP_ROOT+process.env.REACT_APP_UPLOAD+schedule?.contentsList[0]?.individualContents?.fileUrl);
                }
              }
              // if(schedule?.contentsList[0]?.individualContents?.url !== null && schedule?.contentsList[0]?.individualContents?.url !== undefined) {
              //   individualContentUrls.push(process.env.REACT_APP_ROOT+process.env.REACT_APP_UPLOAD+schedule?.contentsList[0]?.individualContents?.url);
              // }
            }

            if (schedule?.contentsList[0]?.schoolCommonContents !== null) {
              if(schedule?.contentsList[0]?.schoolCommonContents?.fileUrl !== null && schedule?.contentsList[0]?.schoolCommonContents?.fileUrl !== undefined) {
                if (process.env.REACT_APP_UPLOAD.startsWith('http')) {
                  schoolcomonContentUrls.push(process.env.REACT_APP_UPLOAD+schedule?.contentsList[0]?.schoolCommonContents?.fileUrl);
                } else {
                  schoolcomonContentUrls.push(process.env.REACT_APP_ROOT+process.env.REACT_APP_UPLOAD+schedule?.contentsList[0]?.schoolCommonContents?.fileUrl);
                }
              }
              // if(schedule?.contentsList[0]?.schoolCommonContents?.url !== null && schedule?.contentsList[0]?.schoolCommonContents?.url !== undefined) {
              //   schoolcomonContentUrls.push(process.env.REACT_APP_ROOT+process.env.REACT_APP_UPLOAD+schedule?.contentsList[0]?.schoolCommonContents?.url);
              // }
            }

            if (schedule?.contentsList[0]?.fileBoxContentsList !== null) {
              schedule?.contentsList[0]?.fileBoxContentsList.map((filebox, i)=> {
                if(filebox.file?.url !== null && filebox.file?.url !== undefined) {
                  if (process.env.REACT_APP_UPLOAD.startsWith('http')) {
                    fileboxContentUrls.push(process.env.REACT_APP_UPLOAD+filebox.file?.url);
                  } else {
                    fileboxContentUrls.push(process.env.REACT_APP_ROOT+process.env.REACT_APP_UPLOAD+filebox.file?.url);
                  }
                }
              })
            }
          })
          
          // console.log(individualContentUrls)
          _clearCache(process.env.REACT_APP_DYNAMIC_INDIVIDUAL_CACHE_NAME, individualContentUrls);
          if (individualContentUrls.length > 0) {
            _saveCache(process.env.REACT_APP_DYNAMIC_INDIVIDUAL_CACHE_NAME, individualContentUrls);
          }
          // console.log(schoolcomonContentUrls)
          if (schoolcomonContentUrls.length > 0) {
            _saveCache(process.env.REACT_APP_DYNAMIC_SCHOOLCOMMON_CACHE_NAME, schoolcomonContentUrls);
          }
          // console.log(fileboxContentUrls)
          if (fileboxContentUrls.length > 0) {
            _saveCache(process.env.REACT_APP_DYNAMIC_FILEBOX_CACHE_NAME, fileboxContentUrls);
          }

          setIsLoading(true);
          // console.log(interval);
          stop();
        } else {
          setElementList(null);
          setIsLoading(false);
          // initSchedule()
        }
      }
      
    }).catch((err) => {
      console.log(err);
      setIsLoading(false);
      // initSchedule()
    })
    setIsApiConnect(true);
  }

  let interval = null;
  function initSchedule() {
    interval = setInterval(async() => {
      // console.log(isLoading)
      if (elementList == null) {
        console.log('null repeat')
        // setIsLoading(false)
        setIsApiConnect(false);
        await playlistSchedule()
      } else {
        stop();
      }
    }, 5000);
  }

  function scheduler() {
    // console.log("--------------------------------")
    clearTimeout(repeatCycle);
    repeatCycle = setTimeout(function schedule() {
      document.body.className = '';
      if (elementList.length > 0) {
        timer = elementList[i].displayTime;  
        setOrder(i)

        if (i < max-1) {
          i = i + 1;
        }
        else { 
          i = 0;
        }

        if (i === 0) {
          if (isLoading) {
            setTimeout(() => {
              // console.log('================= restart =======================')
              setTimeout(() => {
                setIsLoading(false)
                clearTimeout(repeatCycle);
              }, 1000);
              window.location.reload(true);
              window.location.replace('/index');
              // $('#eleId').attr('class','basic');
            }, timer*1000);
          }
        } else {
          setTimeout(function(){
            // setTimeout(() => {
              schedule();
            // }, 1000);
            // window.location.replace('/index');
            // $('#eleId').attr('class','basic');
          }, (timer*1000));
        }
        // $('#eleId').attr('class','basic active');
      }
    })
  }

  useEffect(()=>{
    // console.log(elementList)
    if (elementList && elementList.length > 0) {
      clearInterval(interval);
      clearTimeout(repeatCycle);
      repeatCycle = null;
      // repeatCycle = setInterval(nextFn, (0*1000));
      repeatCycle = setTimeout(scheduler, (0*1000));
    }
  }, [elementList]);

  
  function stop() {
    console.log('stop');
    if (interval != null)
      clearInterval(interval)
  }

  // const { caches } = self;
  function _clearCache(cacheName, contentUrls) {
    if('caches' in window) {
      window.caches.open(cacheName)
      .then((cache) => {
        cache.keys()
          .then((keys) => {
            console.log("cache.keys():", keys);
            keys.forEach((key, index, array) => {
              console.log(key);
              const found = contentUrls.find((e) => e == key.url);
              console.log(`found: `, found);
              if (found === undefined || found === null) {
                console.log(`cache.delete(${key.url})`);
                cache.delete(key);
              }
            });
          });
      });
    }
  }

  function _saveCache(cacheName, contentUrls) {
    // console.log(1111)
    if('caches' in window) {
      // console.log(2222)
      window.caches.open(cacheName)
      .then((cache) => {
        cache.keys()
          .then((keys) => {
            console.log("cache.keys():", keys);
            const cachedUrls = []
            keys.forEach((key, index, array) => {
              console.log(`cachedUrls.push(${key.url})`);
              cachedUrls.push(key.url);
            });
            return cachedUrls;
          })
          .then((cachedUrls) => {
            // console.log("cachedUrls:", cachedUrls);
            // console.log("contentUrls", contentUrls)
            contentUrls
              .filter(contentUrl => {
                const found = cachedUrls.find((e) => e == contentUrl)
                return found === undefined || found === null
              })
              .forEach((contentUrl, index, array) => {
                console.log(`fetch(${contentUrl}, { method: 'GET', mode: 'no-cors', headers: {} })`);
                fetch(contentUrl, { method: 'GET', mode: 'no-cors', headers: {} })
                  .then(response => {
                    if (response.ok && response.status !== 404 && response.status !== 206) {
                      console.log(`Save to cache('${cacheName}'). - ${contentUrl}`);
                      caches.open(cacheName)
                        .then(cache => {
                          cache.put(contentUrl, response.clone());
                        });
                    }
                  })
                  .catch(error => {
                    console.error(error);
                  });
              });
          });
      });
    }
  }

  const [alramContents, setAlramContents] = useState(null);
  const [isGeneralAlertFinish , setIsGeneralAlertFinish] = useState(true);
  const [emergencyContents, setEmergencyContents] = useState(null);
  const [isEmergencyAlertFinish , setIsEmergencyAlertFinish] = useState(true);
  useEffect(()=>{
    setTimeout(() => {
      setEmergencyContents(null)
      setIsEmergencyAlertFinish(true)
    }, emergencyContents?.displayTime * 1000)
  },[emergencyContents]);

  if (elementList === null || elementList.length === 0) {
    initSchedule();
  }
  
  return (
    <>
      {isLoading && order >= 0 && elementList != null && elementList.length > 0 ?
      <li id="eleId" className="basic active">
        {elementList[order].splitScreenType === 'SS1' ? React.createElement(SplitScreen1, {schedule:elementList[order],  setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) : ''}
        <p className="didwalks_logo"><a href='/auth/logout'><span style={{opacity: "0.5"}}>{schoolInfo?.connectCode?.substring(0,5)}</span></a></p>
        {/* <p class="didwalks_logo"><span style={{position: "absolute", clip: "rect(0 0 0 0)", width: "1px", height: "1px",  margin: "-1px", overflow: "hidden"}}>어떤 대체 텍스트</span></p> */}
        {/* <p class="didwalks_logo"><img src={`${process.env.PUBLIC_URL}/images/didwalks_logo.png`} alt="didwalks" /></p> */}
      </li>
      :
      isApiConnect ?
      <div style={{position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", fontSize:"30px"}}>플레이리스트를 설정하시기 바랍니다.</div>
      :
      <></>
      }
      {emergencyContents &&
      <li>
        <EmergencyAlert emergencyContents={emergencyContents} />
      </li>
      }
      {alramContents &&
      <li>
        <GeneralAlert alramContents={alramContents} setIsGeneralAlertFinish={setIsGeneralAlertFinish} />
      </li>
      }
    </>
  )
}

export default Dashboard;