import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import reducers from './reducers';

import storage from 'redux-persist/lib/storage';
import { 
    persistReducer, 
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';


const persistConfig = {
    key : 'root', // 로컬스토리지를 사용할 것이기 때문에 storage를 적어줌
    storage,
    whitelist : ['sch', 'authToken']
}

const persistedReducer = persistReducer(persistConfig, reducers);
  
const store = configureStore({
    //reducer: reducers
    reducer : persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
});

const { dispatch } = store;

const useDispatch = () => useAppDispatch();
const useSelector = useAppSelector;

export {store, dispatch, useSelector, useDispatch};