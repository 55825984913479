import { Route, Routes } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { Error404 } from "../pages";
import Dashboard from "../pages/Dashboard";

export default function MainRoutes() {
    // console.log('MainRoutes')
    return (
        <Routes>
            <Route path="/*" element={ <MainLayout/> }>
                <Route path="index" element={ <Dashboard /> }/>
                <Route path="*" element={ <Error404/> }/>
            </Route>
        </Routes>
    );
}