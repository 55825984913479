import { useEffect, useState } from "react";
import $ from "jquery";

export const ROLE = {
  ALL   : {code : "ALL" , desc : "Login required"},
  R90    : {code : "R90"  , desc : "R90"},
  R10    : {code : "R10"  , desc : "R10"},
};

export const useTitle = () => {
  const [title, setTitle] = useState("DID");
  const updateTitle = () => {
    const htmlTitle = document.querySelector("title");
    htmlTitle.innerHTML = title;
  };
  useEffect(updateTitle, [title]);

  return setTitle;
}

export const getPrecipitationForm = (value) => {
    // console.log(value)
    let result = null;
    if (value == 1)
      result = "비";
    else if (value == 2)
      result = "비/눈";
    else if (value == 3)
      result = "눈";
    else if (value == 4)
      result = "소나기";
    else if (value == 5)
      result = "빗방울";
    else if (value == 6)
      result = "빗방울/눈날림";
    else if (value == 7)
      result = "눈날림";
    else
      result = "강수없음";
// console.log(result)
    return result;
  }

  // 08-17 : 낮, 18-07 : 밤
  export const getSkyStatus = (value) => {
    let result = null;
    if (value == 1)
      result = "맑음";
    else if (value == 2)
      result = "구름조금";
    else if (value == 3)
      result = "구름많음";
    else if (value == 4)
      result = "흐림";

    return result;
  }

  export const getBodyClassName = (precipitationForm, skyStatus) => {
    let result = "weather_sunny";
    if (precipitationForm < 1 || precipitationForm > 7) {
      if (skyStatus == 1)
        result = "weather_sunny";
      else if (skyStatus == 2)
        result = "weather_cloud";
      else if (skyStatus == 3)
        result = "weather_cloudm";
      else if (skyStatus == 4)
        result = "weather_cloudm";
    } else {
      if (precipitationForm == 1)
        result = "weather_rain background";
      else if (precipitationForm == 2)
        result = "weather_snow";
      else if (precipitationForm == 3)
        result = "weather_snow";
      else if (precipitationForm == 4)
        result = "weather_rain background";
      else if (precipitationForm == 5)
        result = "weather_rain background";
      else if (precipitationForm == 6)
        result = "weather_rain background";
      else if (precipitationForm == 7)
        result = "weather_snow";
    }
    return result;
  }

  export const getWeekSkyStatus = (pty, sky) => {
    // console.log(pty)
    // console.log(sky)

    let result = "맑음";
    if (sky == 1) {
      result = "맑음";
    } else {
      if (pty == 1) {
        if (sky == 3) {
          result = "구름많고 비";
        } else if (sky == 4) {
          result = "흐리고 비";
        } else {
          result = "비";
        }
      } else if (pty == 2) {
        if (sky == 3) {
          result = "구름많고 비/눈";
        } else if (sky == 4) {
          result = "흐리고 비/눈";
        } else {
          result = "비/눈";
        }
      } else if (pty == 3) {
        if (sky == 3) {
          result = "구름많고 눈";
        } else if (sky == 4) {
          result = "흐리고 눈";
        } else {
          result = "눈";
        }
      } else if (pty == 4) {
        if (sky == 3) {
          result = "구름많고 소나기";
        } else if (sky == 4) {
          result = "흐리고 소나기";
        } else {
          result = "소나기";
        }
      } else if (pty == 5) {
        result = "빗방울";
      } else if (pty == 6) {
        result = "빗방울/눈날림";
      } else if (pty == 7) {
        result = "눈날림";
      } else {
        if (sky == 1)
          result = "맑음";
        else if (sky == 2)
          result = "구름조금";
        else if (sky == 3)
          result = "구름많음";
        else if (sky == 4)
          result = "흐림";
      }
    }
    return result;
  }

  export const getWeeklyWeather = (sky) => {
    // console.log(sky)
    switch ($.trim(sky)) {
      case "맑음":
          return "ico_wt8";
      case "구름많음":
          return "ico_wt10";
      case "흐림":
          return "ico_wt11";
      case "흐리고 비":
      case "비":
          return "ico_wt1";
      case "흐리고 눈":
      case "눈":
          return "ico_wt3";
      case "흐리고 비/눈":
      case "비/눈":
          return "ico_wt2";
      case "흐리고 소나기":
      case "구름많고 비":
      case "소나기":
          return "ico_wt4";
      case "구름많고 눈":
      case "눈날림":
          return "ico_wt7";
      case "구름많고 비/눈":
      case "빗방울/눈날림":
          return "ico_wt6";
      case "구름많고 소나기":
      case "빗방울":
          return "ico_wt5";
      default:
          return "";
    }
  }

  export const getWeatherClass = (sky, precipitation) => {
    let result = "ico_wt8";
    if (parseInt(precipitation) >= 1 && parseInt(precipitation) <= 7) {
        result = "ico_wt" + precipitation;
    } else {
      if (sky == 1)
        result = "ico_wt8";
      else if (sky == 2)
        result = "ico_wt10";
      else if (sky == 3)
        result = "ico_wt11";
      else if (sky == 4)
        result = "ico_wt9";
    }
    return result;
  }

  export const getWeatherIcon = (sky, precipitation) => {
    let result = null;
    if (parseInt(precipitation) >= 1 && parseInt(precipitation) <= 7) {
      if (precipitation == 1)
        result = "Rainy";
      else if (precipitation == 2)
        result = "Rainy_Snow";
      else if (precipitation == 3)
        result = "Snowing";
      else if (precipitation == 4)
        result = "Rainy_Light";
      else if (precipitation == 5)
        result = "Rainy";
      else if (precipitation == 6)
        result = "Weather_Mix";
      else if (precipitation == 7)
        result = "Sunny_Snowing";
    } else {
      if (sky == 1)
        result = "Sunny";
      else if (sky == 2)
        result = "Partly_Cloudy_Day";
      else if (sky == 3)
        result = "Cloudy";
      else if (sky == 4)
        result = "Foggy";
    }
    return result;
  }

  export const getWindDirection = (value) => {
    let vec = parseInt((parseInt(value) + 11.25) / 22.5);
    let result = convertVec(vec);
    return result;
  }

  export const convertVec = (v) => {
    if (v == 0) {
      return "북";
    } else if (v == 1) {
      return "북북동";
    } else if (v == 2) {
      return "북동";
    } else if (v == 3) {
      return "동북동";
    } else if (v == 4) {
      return "동";
    } else if (v == 5) {
      return "동남동";
    } else if (v == 6) {
      return "남동";
    } else if (v == 7) {
      return "남남동";
    } else if (v == 8) {
      return "남";
    } else if (v == 9) {
      return "남남서";
    } else if (v == 10) {
      return "남서";
    } else if (v == 11) {
      return "서남서";
    } else if (v == 12) {
      return "서";
    } else if (v == 13) {
      return "서북서";
    } else if (v == 14) {
      return "북서";
    } else if (v == 15) {
      return "북북서";
    } else if (v == 16) {
      return "북";
    }
  }

  export const getFineDustGrade = (v) => {
    if (v == 1)
      return "좋음";
    else if (v == 2)
      return "보통";
    else if (v == 3)
      return "나쁨";
    else if (v == 4)
      return "매우나쁨";
    else
      return "확인중";
  }

export const getUvRays = (v) => {
    if (v > 10)
      return "위험";
    else if (v >= 8 && v <= 10)
      return "매우높음";
    else if (v >= 6 && v <= 7)
      return "높음";
    else if (v >= 3 && v <= 5)
      return "보통";
    else if (v >= 0 && v <= 2)
      return "낮음";
  }
  
  export const getCafeteriaType = (type) => {
    if (type === 'BF')
      return "조식";
    else if (type === 'LC')
      return "중식";
    else if (type === 'DN')
      return "석식";
  }

  export const backHtmlspecialchars = (value) => {
		if (value != null && $.trim(value) !== '') {
			value = value.replace(/\&lt;/g, "<");
			value = value.replace(/\&gt;/g, ">");
			value = value.replace(/\&quot;/g, "\"");
			value = value.replace(/\&#34;/g, "\"");
			value = value.replace(/\&#39;/g, "'");
			value = value.replace(/\&#37;/g, "%");
			value = value.replace(/\&#59;/g, ";");
			value = value.replace(/\&#40;/g, "(");
			value = value.replace(/\&#41;/g, ")");
			value = value.replace(/\&#43;/g, "+");
			value = value.replace(/\&amp;/g, "&");
		}
		return value;
	}