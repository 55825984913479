
import React from 'react';
import RootRoutes from './RootRoutes';

function App() {
  // console.log('app')
    return (
      <>
        <RootRoutes/>
      </>
    );
}

export default App;