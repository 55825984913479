import { useEffect, useRef, useState } from "react";
import $ from "jquery";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

function GeneralAlert({alramContents:contents, setIsGeneralAlertFinish}) {
    // const [generalCount, setGeneralCount] = useState(0);
    // // const [emergencyCount, setEmergencyCount] = useState(0);
    // let alertRepeat = null;
    // useEffect(()=>{
    //   clearInterval(alertRepeat)
    //   alertRepeat = setInterval(()=>{
    //     console.log(generalCount);
    //     if (generalCount < 3) {
    //       setGeneralCount(prev=>prev+1);
    //     } else {
    //         clearInterval(alertRepeat)
    //     }
    //   },8000);

    //   return () => clearInterval(alertRepeat);
    //   // "[2023.12.01] - AI 및 4차산업 디지털 융합교육 시행[2023.12.01] - AI 및 4차산업 디지털 융합교육 시행[2023.12.01]"
    // },[generalCount]);

    // useEffect(()=>{
    //     console.log(contents.length)
    //     $('.alram_p').style = "width: 300px";
    // },[])


    // .general_alram .content{
    //     position: relative;
    //     width: 100%;
    //     animation: scroll-animation 20s linear infinite;
    // }
    // @keyframes scroll-animation{
    //     0%{
    //       left: 1920px;
    //     }
    //     100%{
    //       left: -4410px;
    //     }
    // }
    // const scroll_animation = keyframes`
    //     0%{
    //           left: 1920px;
    //         }
    //     100%{
    //         left: -${contents.length * 40}px;
    //     }
    // `;
    // const ContentLi = styled.li`
    //     position: relative;
    //     width: 100%;
    //     animation: ${scroll_animation} 30s linear infinite;
    //     `
    // ;

    // console.log(`
    // position: relative;
    // width: 100%;
    // animation: ${scroll_animation} 30s linear infinite;
    // `)

    const contentRef = useRef();
    const [alertContent, setAlertContent] = useState(null);
    useEffect(()=>{
        if (contents) {
            let conts = contents?.contents.split(/\n/);
            // console.log(conts.length)
            let content = '';
            conts.map((con, i)=>{
                content += $.trim(con)
            })
            // console.log(content)
            setAlertContent(content)
            // console.log(contents?.contents.length * 22.5)
            // console.log(window.outerWidth)
            // console.log(((contents?.contents.length * 22.5) / window.outerWidth))
            // console.log(parseInt(parseInt(contents?.contents.length * 22.5) / window.outerWidth))
            // // console.log('>>' + (3037 / 1920))
            // // console.log('>>' + (30 / 19))
            // console.log((((contents?.contents.length * 22.5) / window.outerWidth)*2.8))
            // console.log(parseInt(window.outerWidth+(((contents?.contents.length * 22.5) / window.outerWidth)*2.8)))
            // console.log(parseInt(contents?.contents.length * 25) + window.outerWidth)
            // contentRef.current.style.setProperty("--left", `-${window.outerWidth+(((contents?.contents.length * 22.5) % window.outerWidth)*10)}px`);
            // contentRef.current.style.setProperty("--left", `-${window.outerWidth+parseInt(((contents?.contents.length * 22.5) / window.outerWidth)*window.outerWidth)}px`);
            
            
            // contentRef.current.style.setProperty("--lefts", `${window.outerWidth}px`);
            // contentRef.current.style.setProperty("--lefte", `-${(parseInt(content.length * 26) + window.outerWidth)}px`);

            // let $box = $('#alertBox');
            let $contents = $('.alram_p');
            $contents.stop(true, true);
            $contents.css('left', window.outerWidth);
            $contents.children().remove();
            
            for(var i=0; i<3; i++) {
                if(i != 0) {
                    $contents.append($(`<span style="display: inline-block; width: ${window.outerWidth}px;"></span>`));
                }
                $contents.append($(`<span style="display: inline-block; white-space: nowrap;">${content}</span>`));
            }
            
            let totalWidth = 0;
            let $children = $contents.children();
            for(var i=0; i<$children.length; i++) {
                totalWidth += $children.eq(i).outerWidth();
            }
            // console.log(totalWidth)
            $contents.css('width', totalWidth+100);
            let animateTime = 60000; // 20초
            $contents.delay(500).animate({
                    left: ($contents.outerWidth() * -1) + 'px'
                }
                , animateTime
                , "linear"
                , function () {
                    // console.log(111)
                    if(setIsGeneralAlertFinish)
                        setIsGeneralAlertFinish(true)
                }
            );
        } else {
            if (setIsGeneralAlertFinish)
            setIsGeneralAlertFinish(true)
        }
    },[contents])

    return (
        <div className="general_alram">
            <div>
                <ul className="track">
                    <li className="content" ref={contentRef}>
                        {/* <p className="alram_p" style={{width: `${alertContent.length * 26}px`}}>{alertContent}</p> */}
                        <p className="alram_p"></p>
                    </li>
                </ul>
            </div>
            <hr/>
        </div>
        // {/* <div className="general_alram">
        //     <div className="body"></div>
        //     <div className="track">
        //         <div className="content"><p>유치원 긴급돌봄교실은 운영하지 않습니다.유치원 긴급돌봄교실은 운영하지 않습니다</p></div>
        //     </div>
        // </div> */}
    )
}

export default GeneralAlert;