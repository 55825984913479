import { useEffect, useRef, useState } from "react";
import { backHtmlspecialchars, getWeatherClass, getWeekSkyStatus } from "../../data/func";
import { postContents } from "../axios/customAxios";
import TodayComponentWI from "../TodayComponentWI";
import $, { trim } from "jquery";
// import ErrorPage from "../../pages/ErrorPage";

function WeatherInfo({isLast=false, title, individualContentsSeqNo, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
        getWeatherInformation();
    }, [individualContentsSeqNo]);

    const containerRef = useRef(null);
    const textRef = useRef(null);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [contents, setContents] = useState(null);
    const [contents2, setContents2] = useState(null);
    const [weatherImg, setWeatherImg] = useState("ico_wt8");
    const [weatherText, setWeatherText] = useState("맑음");
    const [basicWeather, setBasicWeather] = useState({});
    function getWeatherInformation() {
        Promise.all([
            postContents('/api/contents/weather',{isAlert:false}).then((res)=>{
                // console.log(res);
                if (res && res.error == null && res.response != null) {
                    let basic = res.response.basicWeather;
                    // console.log(basic)
                    setBasicWeather(basic);
                    if (basic != null) {
                        setWeatherImg(getWeatherClass(basic.skyStatus, basic.precipitationForm))
                        setWeatherText(getWeekSkyStatus(basic.precipitationForm, basic.skyStatus))
                    } else {
                        setWeatherImg("ico_wt8");
                        setWeatherText("맑음")
                    }
                } else {
                    setBasicWeather({})
                }
            }),
            postContents('/api/contents/individual/'+individualContentsSeqNo,{isAlert:!isLast}).then((res)=>{
                // console.log(res);
                if (res && res.error == null && res.response != null && res.response.detail != null) {
                    setContents(res.response.detail);
                    let contents2 = backHtmlspecialchars(res?.response?.detail?.contents2);
                    if (contents2) {
                        contents2 = contents2.replace(/\r\n/ig, '<br>');
                        contents2 = contents2.replace(/\\n/ig, '<br>');
                        contents2 = contents2.replace(/\n/ig, '<br>');
                        if (contents2.indexOf("<br>") !== -1) {
                            contents2 = contents2.replace(/\<br>/g, '<br/>');
                        }
                        setContents2(contents2.split('<br/>'))
                    } else {
                        setContents2(null);
                    }
                } else {
                    if (res?.error != null) {
                        setError(res?.error);
                    }
                    setContents(null);
                }

                if (res && res.error == null && res.alert != null) {
                    if (res.alert.emergency) {
                        // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                        if (isEmergencyAlertFinish) {
                            setEmergencyContents(res.alert.emergency);
                            setIsEmergencyAlertFinish(false)
                        }
                    }

                    if (res.alert.general) {
                        // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                        if (isGeneralAlertFinish) {
                            setAlramContents(res.alert.general);
                            setIsGeneralAlertFinish(false)
                        }
                    }
                }
            })
        ]).then(()=>{
            setIsLoading(true);
        })
    }

    useEffect(()=>{
        if (isLoading) {
            if (containerRef.current?.offsetHeight > textRef.current?.offsetHeight) {
                textRef.current?.style.setProperty('animation','none');
                textRef.current?.style.setProperty('top','0');
            } else {
                textRef.current?.style.setProperty('animation','scrollText 50s linear infinite');
            }
        }
    },[isLoading]);

    if (contents != null) {
        return (
        // <div className="scene_notice_weather animate__animated animate__bounceIn">
        <div className="scene_notice_weather">
            <div>
                <div className="weather_time_bg">
                    {contents && contents.fileUrl ?
                    <img src={`${process.env.REACT_APP_UPLOAD}${contents && contents.fileUrl}`} alt="" />
                    :
                    <img src={`${process.env.PUBLIC_URL}/images/scene_notice_weather_bg.png`} alt="" />
                    }
                </div>
                <div className="weather_time_box">
                    <div className="in_weather_time">
                        {basicWeather && 
                        <div className="in_weather">
                            <div className="weather_img">
                                <img src={`${process.env.PUBLIC_URL}/images/${weatherImg}.svg`} style={{filter: "drop-shadow(2px 2px 1px rgba(0, 0, 0, 0.3))"}} alt="" />
                            </div>
                            <div className="weather_in_cont">
                                <b className={`wt_icon ${weatherImg}`}>
                                    <span>{weatherText}</span>
                                </b> 
                                <div className="temperature_text"> 
                                    <strong><span className="blind">현재 온도</span>{basicWeather && basicWeather.temperature}<span className="celsius">°</span></strong> 
                                </div> 
                            </div>
                        </div>
                        }
                        <TodayComponentWI />
                    </div>

                    <div className="weather_time_cont">
                        <span className="highlight">{contents.title}</span>
                        <div></div>
                        <div className="weather_time_tit"><p dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents1)}}></p></div>
                        <div id="weather_time_contbox" ref={containerRef}>
                            <div id="autoscroll" ref={textRef} style={{whiteSpace:"pre-wrap"}}>
                            {contents2 
                                && contents2.map((item, i)=>{
                                    if (item != null && item != '')
                                    return (<div key={i} dangerouslySetInnerHTML={{__html:item}}></div>)
                                })
                            }
                            </div>
                        </div>
                        <span><b dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents3)}}></b></span>
                    </div>
                </div>
            </div>
            <hr/>
        </div>
        )
    // } else {
    //     return (
    //         <ErrorPage title={"공지-"+title} error={error} />
    //     )
    }
}

export default WeatherInfo;