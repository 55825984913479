import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import $ from "jquery";

import { postContents } from '../axios/customAxios';
import { getFineDustGrade, getPrecipitationForm, getSkyStatus, getWeatherClass, getWindDirection, getUvRays, getWeeklyWeather, getBodyClassName } from '../../data/func';
import { snow } from '../../data/snow';
// import '../../rain.css';

function Weather({isLast=false, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    dayjs.locale('ko');
    const [bodyClassName, setBodyClassName] = useState('weather_sunny');
    useEffect(()=>{
        getTodayWeather();
    }, []);

    const [basicWeather, setBasicWeather] = useState({});
    const [weeklyWeather, setWeeklyWeather] = useState([]);
    const [crawlingDate, setCrawlingDate] = useState(null);
    function getTodayWeather() {
        postContents('/api/contents/weather',{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null) {
                setBasicWeather(res.response.basicWeather);
                setWeeklyWeather(res.response.weeklyWeather);
                setCrawlingDate(res.response.crawlingDate);
                let bodyClassName = 'weather_sunny';
                bodyClassName = getBodyClassName(res.response.basicWeather.precipitationForm, res.response.basicWeather.skyStatus);
                // bodyClassName = 'weather_rain background'
                setBodyClassName(bodyClassName);
                if (bodyClassName.indexOf(' ') != -1) {
                    bodyClassName.split(' ').map((className)=>{
                        document.body.classList.add(className);
                    })
                } else {
                    document.body.classList.add(bodyClassName);    
                }
                if (bodyClassName === 'weather_snow') {
                    setTimeout(() => {
                        snow();
                    }, 500);
                }
    
                if (bodyClassName === 'weather_rain background') {
                    setTimeout(() => {
                        const script2 = document.createElement("script");
                        script2.src = "https://cdnjs.cloudflare.com/ajax/libs/jquery.ripples/0.5.3/jquery.ripples.js";
                        $('#ground').after(script2);
                        setTimeout(() => {
                            const script3 = document.createElement("script");
                            script3.src = "/js/rain.js";
                            $('#ground').after(script3);
                        }, 1000);
                    }, 1000);
                }
            } else {
                setBasicWeather({})
                setWeeklyWeather([])
                setCrawlingDate(null)
            }

            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    return (
        // <div className="scene_weather animate__animated animate__bounceIn">
        <div className="scene_weather">
            {bodyClassName && bodyClassName === 'weather_snow' &&
				<canvas id="sky"></canvas>
            }
            {bodyClassName && bodyClassName === 'weather_rain background' &&
                <div id="ground"></div>
            }
            {bodyClassName && (bodyClassName === 'weather_cloud' || bodyClassName === 'weather_cloud weather_cloudm' || bodyClassName === 'weather_cloudm') &&
                <div className='clouds'>
					<div className='clouds-1'></div>
					<div className='clouds-2'></div>
					<div className='clouds-3'></div>
				</div>
            }
            <div className="scene_weather_box">
                <div>
                    {basicWeather &&
                    <div className="_today"> 
                        <div className="weather_graphic"> 
                            <div className="weather_main"> 
                                <i className={`wt_icon ${getWeatherClass(basicWeather.skyStatus, basicWeather.precipitationForm)}`}>
                                    <span className="blind">{(basicWeather.precipitationForm < 1 || basicWeather.precipitationForm > 7) ? getSkyStatus(basicWeather.skyStatus) : getPrecipitationForm(basicWeather.precipitationForm)}</span>
                                </i> 
                            </div> 
                            <div className="temperature_text"> 
                                <strong>
                                    <span className="blind">현재 온도</span>{basicWeather.temperature}<span className="celsius">°</span>
                                </strong>
                            </div> 
                        </div> 
                        <div className="temperature_info"> 
                            <p className="summary">
                                <span className="weather_txt before_slash">{(basicWeather.precipitationForm < 1 || basicWeather.precipitationForm > 7) ? getSkyStatus(basicWeather.skyStatus) : getPrecipitationForm(basicWeather.precipitationForm)}</span> 
                            </p> 
                            <dl className="summary_list"> 
                                <div className="sort"> 
                                    <dt className="term">습도</dt> 
                                    <dd className="desc">{basicWeather.humidity}%</dd> 
                                </div> 
                                <div className="sort"> 
                                    <dt className="term">{getWindDirection(basicWeather.windDirection)}</dt> 
                                    <dd className="desc">{basicWeather.windSpeed}m/s</dd> 
                                </div> 
                            </dl> 
                        </div>
                        
                        <div className="report_card_wrap"> 
                            <ul className="today_chart_list"> 
                                <li className="item_today level3"> 
                                    <strong className="title">미세먼지</strong> <span className="txt">{getFineDustGrade(basicWeather.fineDustGrade)}</span>
                                </li> 
                                <li className="item_today level4"> 
                                    <strong className="title">초미세먼지</strong> <span className="txt">{getFineDustGrade(basicWeather.ultraFineDustGrade)}</span>
                                </li>
                                <li className="item_today level2"> 
                                    <strong className="title">자외선</strong> <span className="txt">{getUvRays(basicWeather.uvRay)}</span>
                                </li> 
                                <li className="item_today type_sun">
                                    <strong className="title">일몰</strong> <span className="txt">{basicWeather.sunsetTime && basicWeather.sunsetTime.substring(0,2)}:{basicWeather.sunsetTime && basicWeather.sunsetTime.substring(2,4)}</span>
                                </li> 
                                <li className="item_today level2">
                                    <strong className="title">오존</strong> <span className="txt">{getFineDustGrade(basicWeather.ozoneGrade)}</span>
                                </li>
                            </ul> 
                        </div>
                        <br/>
                        <div style={{marginLeft:"10px"}}>
                            ※ 데이터 오류 가능성: 데이터는 실시간 관측된 자료이며 측정소 현지 사정이나 데이터의 수신상태에 따라 미수신 될 수 있음<br/>
                            ※ 출처: 환경부/한국환경공단(대기오염,측정소), 기상청(단기예보,중기예보,생활기상지수), 한국천문연구원(출몰시각)<br/>
                            ※ {dayjs(crawlingDate).format('YYYY년 MM월 DD일 HH시')} 기준
                        </div>
                    </div>
                    }

                    {weeklyWeather &&
                    <div className="weather_inner">
                        <span className="week_tit">주간예보</span>
                        <ul>
                        {weeklyWeather.map((week_item, index)=>{
                            const day = dayjs(week_item.date);
                            return(
                            <li key={index} className={`week_item ${day.format("ddd") === '일' ? 'holiday' : 'today'}`}>
                                <div className="day_data"> 
                                    <div className="cell_date"> 
                                        <span className="date_inner"> 
                                            <strong className="day">{day.format("ddd")}</strong> <span className="date">{day.format("MM.DD.")}</span> 
                                        </span> 
                                    </div> 
                                    <div className="cell_weather"> 
                                        <span> 
                                            <span className="weather_left"> 
                                                <strong className="time">오전</strong> <span className="rainfall">{week_item.morningPrecipitation}%</span> 
                                            </span> 
                                            <i className={`wt_icon ${getWeeklyWeather(week_item.morningSkyStatus)}`}><span className="blind">{week_item.morningSkyStatus}</span></i> 
                                        </span> 
                                        <span> 
                                            <span className="weather_left"> 
                                                <strong className="time">오후</strong> <span className="rainfall">{week_item.afternoonPrecipitation}%</span> 
                                            </span> 
                                                <i className={`wt_icon ${getWeeklyWeather(week_item.afternoonSkyStatus)}`}><span className="blind">{week_item.afternoonSkyStatus}</span></i> 
                                        </span> 
                                    </div> 
                                    <div className="cell_temperature"> 
                                        <span className="temperature_inner"> 
                                            <span className="lowest">
                                                <span className="blind">최저기온</span>{week_item.lowestTemperature && parseInt(week_item.lowestTemperature)}°
                                            </span> 
                                            <span className="bar">/</span> 
                                            <span className="highest">
                                                <span className="blind">최고기온</span>{week_item.highestTemperature && parseInt(week_item.highestTemperature)}°
                                            </span> 
                                        </span> 
                                    </div> 
                                </div> 
                            </li>
                            )
                        })}
                        </ul>
                    </div>
                    }
                </div>
            </div>
            <hr/>
            <style jsx="true">{`
                @import '/css/rain.css';
            `}</style>
        </div>
    );
}

export default Weather;
