import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import styles from '../Login.module.css';
import useModals from '../components/modals/useModal';
import { modals } from '../components/modals/Modals'; 
import { useNavigate } from "react-router-dom";
import { getConnectCode, getConnectionInfoByCode, setClientHeaders } from "../components/axios/customAxios";
import { useDispatch } from "react-redux";
import { setSchool } from "../store/reducers/schoolSlice";
import { LOGIN } from "../store/reducers/auth";

const ConnectPage = () => {
    // console.log('ConnectPage')
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // modal 관련
    const { openModal } = useModals();
    let [alertMessage, setAlertMessage] = useState('');

    const handleAlertClick = () => {
        openModal(modals.alertModal, {
            onClose : () => {
            },
            message: alertMessage,
        });
    };

    const [connectCode, setConnectCode] = useState(null);
    const TIMER = 60;
    let timerId1 = null;
    let timerId2 = null;
    // const [error, setError]             = useState(false);
    // let connectCode = null;

    useEffect(()=>{
        // document.html.classList.add(styles.html)
        document.body.classList.add(styles.body);
        // console.log(connectCode);
        connectCheck();
        // let interval = null;
        // // clearInterval(interval)
        // interval = setInterval(() => {
        // // console.log(value.current)
        // // setInterval(()=>{
        //     connectCheck()
        //     // setCount((prev) => prev + 1);
        //     // value.current++;
        // }, (5*1000));
        // return () => clearInterval(interval);
    },[connectCode])

    async function connectCodeFn() {
        await getConnectCode().then((res) => {
            // console.log(res);
            if (res?.response) {
                // setError(res?.response?.isConnectFull);
                setConnectCode(res?.response?.connectCode);
                clearInterval(timerId1);
            }
        }).catch((err) => {
            console.log(err);
            // window.location.replace('/auth/logout');
        });
    }

    async function connectionInfoFn() {
        await getConnectionInfoByCode(connectCode).then((res) => {
            // console.log(res);
            if (res?.response && res?.response?.tokens) {
                // console.log(res)
                dispatch(LOGIN(res?.response?.tokens));
                setClientHeaders(res?.response?.tokens?.accessToken);
                dispatch(setSchool({ schoolName: res?.response?.didName, playlistConnectCode: res?.response?.playlistConnectCode }));
                clearInterval(timerId2);
                navigate('/index');
            } else {
                if (res?.response) {
                    // console.log(333?.response?.connectCode)
                    if (connectCode !== res?.response?.connectCode) {
                        setConnectCode(res?.response?.connectCode);
                        clearInterval(timerId2);
                    }
                }
            }
        }).catch((err) => {
            console.log(err);
            // window.location.replace('/auth/logout');
        });
    }

    const connectCheck = () => {
        if (connectCode == null) {
            connectCodeFn();
            timerId1 = setInterval(()=>{
                connectCodeFn();
            }, (TIMER*1000));
        } else {
            connectionInfoFn();
            timerId2 = setInterval(()=>{
                connectionInfoFn();
            }, (TIMER*1000));
        }
    };
    
    if (connectCode != null) {
        return (
            <Box component="form" noValidate sx={{ mt: 1 }}>
                <div className={styles.content_school}>
                    {connectCode}
                </div>
                <div style={{marginTop:"20px", marginBottom:"20px", fontSize:"20px", textAlign:"center"}}>
                    DID 관리자에게 위의 코드로 승인요청을 하여 주시기 바랍니다.<br/>
                    승인처리가 되면 자동으로 서비스 화면으로 이동됩니다.
                </div>
            </Box>
        )
    }
}

export default ConnectPage;