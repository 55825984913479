import axios from "axios";
import { useDispatch } from "react-redux";
// import { LOGOUT } from "store/reducers/auth";
// import { useLocation } from "react-router-dom";

export const setClientHeaders = (token) => {
   // console.log('=========================');
   // console.log(token);
   const authToken = (token || (localStorage.getItem("persist:root") && JSON.parse(localStorage.getItem("persist:root")).authToken && JSON.parse(JSON.parse(localStorage.getItem("persist:root")).authToken).accessToken));
   customAxios.interceptors.request.use(function (config) {
      config.headers.Authorization = `Bearer ${authToken}`;
      // console.log(config)
      return config;
   },
   function (error) {
      // Do something with request error
      console.log("request error", error);
      return Promise.reject(error);
   })

   customAxios.interceptors.response.use(
      async (response) => {
         // console.log(response)
         if (response?.data?.isServiceUpdate) {
            // window.location.replace(`${process.env.REACT_APP_ROOT}/update/inspection.html`);
            window.location.replace(`/update/inspection.html`);
            return;
         }
         if (response?.data?.error != null) {
            if (response.data?.error?.code === 'E0002' || response.data?.error?.code === 'E1001' || response.data?.error?.code === 'E1002') {
               // alert(response.data?.error?.message);
               // console.log(response.data?.error?.message);
               window.location.reload(true);
               window.location.replace('/auth/logout');
               return;
            }
         }
         return response;
      },
      async (err) => {
         console.log(err)
         const { config
            , response: { status }
            } = err;
         //  console.log(status)
         const authToken = (localStorage.getItem("persist:root") && JSON.parse(localStorage.getItem("persist:root")).authToken && JSON.parse(JSON.parse(localStorage.getItem("persist:root")).authToken).accessToken);
         // console.log(config)
         // console.log(authToken)
         if (status === 403 && authToken != null) {
         // if ( authToken != null) {
            // if (err.message === 'Request failed with status code 403') {
            // console.log(err.response.data.error.code);
            // console.log(err.response.data?.error?.code?.indexOf('T000'))
            if (err.response.data?.error?.code && err.response.data?.error?.code?.indexOf('T000') != -1) {
               // const originRequest = config;
               console.log("로그인 시간이 만료되었습니다.");
               window.location.reload(true);
               window.location.replace('/auth/logout');
            } else {
               getNotifyUpdate().then((res)=>{
                  console.log(res);
                  if (res?.isServiceUpdate) {
                     // console.log(1212);
                     window.location.reload(true);
                     // window.location.replace(`${process.env.REACT_APP_ROOT}/update/inspection.html`);
                     window.location.replace(`/update/inspection.html`);
                  }
               }).catch((err)=>{
                  console.log(err);
                  console.log('fail');
               })
            }
         } else if (String(status).startsWith("50")) {
            // console.log(11111111)
            window.location.reload(true);
            // window.location.replace(`${process.env.REACT_APP_ROOT}/update/inspection.html`);
            window.location.replace(`/update/inspection.html`);
         }
         return Promise.reject(err);
      }
   )

   // customPatchAxios.interceptors.request.use(function (config) {
   //    config.headers.Authorization = `Bearer ${authToken}`;
   //    return config;
   // })

   // customFileAxios.interceptors.request.use(function (config) {
   //    config.headers.Authorization = `Bearer ${authToken}`;
   //    return config;
   // })

   // customFileDownAxios.interceptors.request.use(function (config) {
   //    config.headers.Authorization = `Bearer ${authToken}`;
   //    return config;
   // })
   
};

export const loginInterceptor = () => {
   customLoginAxios.interceptors.response.use(
      async (response) => {
         console.log(response)
         if (response?.data?.isServiceUpdate) {
            // window.location.replace(`${process.env.REACT_APP_ROOT}/update/inspection.html`);
            window.location.replace(`/update/inspection.html`);
         }
         return response;
         // if (response?.data?.error != null) {
         //    if (response.data?.error?.code === 'E0002' || response.data?.error?.code === 'E1001' || response.data?.error?.code === 'E1002') {
         //       alert(response.data?.error?.message);
         //       window.location.reload(true);
         //       window.location.replace('/auth/logout');
         //    }
         // } else {
         //    return response;
         // }
      },
      async (err) => {
         console.log(err)
         const { config , response: { status }
         } = err;
         return Promise.reject(err);
      }
   )
}


export const customLoginAxios = axios.create({
   baseURL : process.env.REACT_APP_API_URL,
   headers : {
    'Content-type' : 'application/json; charset=UTF-8',
    accept : 'application/json,',
   }
});

export const customAxios = axios.create({
   baseURL : process.env.REACT_APP_API_URL,
   headers : {
    'Content-type' : 'application/json; charset=UTF-8',
    accept : 'application/json,',
   //  'Authorization': `Bearer ${localStorage.getItem("persist:root") && JSON.parse(localStorage.getItem("persist:root")).authToken && JSON.parse(JSON.parse(localStorage.getItem("persist:root")).authToken).accessToken}`
   }
});

// export const customPatchAxios = axios.create({
//   baseURL : process.env.REACT_APP_API_URL,
//   headers : {
//    'Content-type' : 'application/merge-patch+json; charset=UTF-8',
//    accept : 'application/json,',
//    // 'Authorization': `Bearer ${localStorage.getItem("persist:root") && JSON.parse(localStorage.getItem("persist:root")).authToken && JSON.parse(JSON.parse(localStorage.getItem("persist:root")).authToken).accessToken}`
//   }
// });

// export const customFileAxios = axios.create({
//    baseURL : process.env.REACT_APP_API_URL,
//    headers : {
//     'Content-Type' : 'multipart/form-data',
//     accept : 'application/json,',
//    //  'Authorization': `Bearer ${localStorage.getItem("persist:root") && JSON.parse(localStorage.getItem("persist:root")).authToken && JSON.parse(JSON.parse(localStorage.getItem("persist:root")).authToken).accessToken}`
//    }
//  });
 
// export const customFileDownAxios = axios.create({
//    baseURL : process.env.REACT_APP_API_URL,
//    // headers : {
//    //  'Content-type' : 'application/json; charset=UTF-8',
//    //  accept : 'application/json,',
//    // 'Authorization': `Bearer ${localStorage.getItem("persist:root") && JSON.parse(localStorage.getItem("persist:root")).authToken && JSON.parse(JSON.parse(localStorage.getItem("persist:root")).authToken).accessToken}`
//    // },
//    responseType: "blob" ,
// });

/*********************************************************************** */

//  /**
//   * 접속코드로 정보가져오기
//   * @param {*} connectCode 
//   * @returns 
//   */
//  export const getConnectionByCode= async(connectCode)=>{
//    const res = await customLoginAxios.get("/playlist/login/"+connectCode);
//    // console.log(res)
//    if (res && res.data) {
//       //return Object.values(res.data);
//       return res.data;
//    }
//    return null;
// }

 
//  /**
//   * 접속코드와 셋탑코드로 정보가져오기
//   * @param {*} connectCode 
//   * @param {*} playlistId
//   * @returns 
//   */
//  export const getConnectionByCodeAndPlaylist = async(connectCode, playlistId)=>{
//    const res = await customLoginAxios.get("/playlist/login/"+connectCode+"/"+playlistId);
//    // console.log(res)
//    if (res && res.data) {
//       //return Object.values(res.data);
//       return res.data;
//    }
//    return null;
// }

/*********************************************************************** */

/**
 * 접속코드 가져오기
 * @returns 
 */
export const getConnectCode= async()=>{
   const res = await customLoginAxios.get("/playlist/login2");
   // console.log(res)
   if (res && res.data) {
      //return Object.values(res.data);
      return res.data;
   }
   return null;
}

 
/**
 * 접속코드로 접속정보가져오기
 * @param {*} connectCode 
 * @returns 
 */
export const getConnectionInfoByCode = async(connectCode)=>{
   const res = await customLoginAxios.get("/playlist/login2/"+connectCode);
   // console.log(res)
   if (res && res.data) {
      //return Object.values(res.data);
      return res.data;
   }
   return null;
}

export const getPlaylistLogout = async()=>{
   try {
      const res = await customAxios.get("/playlist/logout");
      // console.log(res)
      if (res && res.data) {
         //return Object.values(res.data);
         return res.data;
      }
      return null;
   }catch(error) {
      console.log(error)
   }
}

/**
  * 점검중 여부
  * @param {*} connectCode 
  * @returns 
  */
export const getNotifyUpdate= async()=>{
   const res = await customLoginAxios.get("/notify/update");
   if (res && res.data) {
      return res.data;
   }
   return null;
}

/*********************************************************************** */

export const getPlaylistSchedule = async()=>{
   try {
      const res = await customAxios.get('/api/playlist/schedule');
      // console.log(res);
      if (res && res.data) {
         //return Object.values(res.data);
         return res.data;
      }
      return null;
   }catch(error) {
      console.log(error)
   }
}

export const postContents = async(url, params)=>{
   try {
      const res = await customAxios.post(url, params);
      // console.log(res);
      if (res && res.data) {
         //return Object.values(res.data);
         return res.data;
      }
      return null;
   }catch(error) {
      console.log(error)
   }
}

export const postLastConnectionLog = async(body)=>{
   //console.log(body);
   await customAxios.post('/api/playlist/lastConnection',body);
}

