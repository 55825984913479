import { useEffect, useState } from "react";
import dayjs from "dayjs";
import 'dayjs/locale/ko';

import { postContents } from "../axios/customAxios";

function SchoolSchedule({isLast=false, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    dayjs.locale('ko');
    useEffect(()=>{
        getSchoolScheuleList();
    }, []);

    const curMonth = dayjs(new Date()).format('YYYY년 MM월');
    const curDay = dayjs(new Date()).format('DD');
    var days = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getDate();
    // console.log(days)
    const [dataList, setDataList] = useState(null);
    const [dataList2, setDataList2] = useState(null);
    const [crawlingDate, setCrawlingDate] = useState(null);
    function getSchoolScheuleList() {
        postContents('/api/contents/school/schedule',{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null) {
                const dataList = res.response.list;
                setDataList(dataList);
                let dataList2 = [];
                let dateIdx = 0;
                let itemCopy = {};
                dataList && dataList.map((item, index)=>{
                    if (parseInt(curDay) <= parseInt(item.dayOfMonth) && dateIdx < 8) {
                        dateIdx++;
                        let weekStr = dayjs(item.year+'-'+item.month+'-'+item.dayOfMonth).format('ddd');
                        itemCopy = {...item};
                        itemCopy.weekStr = weekStr;
                        if (parseInt(curDay) === parseInt(item.dayOfMonth)) {
                            itemCopy.isToday = true;
                        } else {
                            itemCopy.isToday = false;
                        }
                        dataList2.push(itemCopy);
                    }
                })
                setDataList2(dataList2);
                setCrawlingDate(res.response.crawlingDate);
            } else {
                setDataList(null)
                setDataList2(null)
                setCrawlingDate(null)
            }

            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    return (
        // <div className="scene_calendar animate__animated animate__bounceIn">
        <div className="scene_calendar">
            <div>
                <div className="calendar">
                    <div className="now_month">{curMonth}</div>
                    <ul>
                        {[...Array(days)].map((item, index) => {
                            const dayOfWeek = dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), (index+1))).format('ddd');
                            const dateStr = dayjs(new Date(new Date().getFullYear(), new Date().getMonth(), (index+1))).format('DD');
                            let className = "";
                            if (dayOfWeek === '토')
                                className = "saturday";
                            else if (dayOfWeek === '일')
                                className = "sunday";
                            let data = dataList && dataList.find(sch=>sch.dayOfMonth === dateStr);
                            let existDateClassName = '';
                            if (data != null) {
                                existDateClassName = 'schedule_on';
                            }
                            return (
                                <li key={index}>
                                    <span className={className}>{dayOfWeek}</span>
                                    <span className={existDateClassName}>{String(index+1).padStart(2,'0')}</span>
                                </li>
                            )
                        }
                        )}
                    </ul>
                </div>
                <hr/>

                <div className="calendar_content">
                    <ul>
                        {dataList2 && dataList2.map((item, index)=>{
                            let todayClass = '';
                            if (item.isToday) {
                                todayClass = 'this_day'
                            }
                            return (
                            <li key={index} className={todayClass}>
                                <p><span className="material-symbols-outlined">date_range</span><span>{item.month}.{item.dayOfMonth}({item.weekStr})</span></p>
                                <p>- {item.eventNm}</p>
                            </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
            <hr/>
        </div>
    )
}

export default SchoolSchedule;