import { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import { postContents } from "../axios/customAxios";
import { backHtmlspecialchars } from "../../data/func";

function TileType({isLast=false, title, fileContentsSeqNos, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
		getTileType()
    }, []);
    // var _URL = window.URL || window.webkitURL;
    const [error, setError] = useState(null);
    // const [contents, setContents] = useState(null);
    const [photos, setPhotos] = useState();
    function getTileType() {
        let params = {
            seqNos : fileContentsSeqNos,
            isAlert:!isLast
        }
        postContents('/api/contents/fileBox',params).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null && res.response.detail != null) {
                const contents = res.response.detail;
                // setContents(content);
                if (contents) {
                    document.body.classList.add("gallery");
                    // var imgObj = document.getElementById('uploadedImage');
                    let photos = contents.map((item) => {
                        // let newImg = new Image();
                        // newImg.src = process.env.REACT_APP_UPLOAD+item.file.url;
                        // console.log(newImg);
                        // console.log(newImg.naturalWidth);
                        // console.log(newImg.naturalHeight);

                        //return {id:item.seqNo, src:process.env.REACT_APP_UPLOAD+item.file.url, width:1200, Height:500, contents:item.contents, type:item.file.type}
                        return {id:item.seqNo, src:process.env.REACT_APP_UPLOAD+item.file.url, contents:item.contents, type:item.file.type}
                    })
                    // console.log(photos)
                    setPhotos(photos);
                } else {
                    setPhotos(null);
                }
            } else {
                if (res?.error != null) {
                    setError(res?.error);
                }
                setPhotos(null);
            }

            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    return (
        <div className="scene_gallery">
            <div>
            <div className="gallery_box"><p className="gallery_tit">{backHtmlspecialchars(title)}</p></div>
                <div className="gallery_row">
                    <ul className="images">
                        {photos && photos.map((item, i)=> {
                            if (item.type === "I") {
                                return (
                        <li key={i} className="img">
                            <img src={item.src} alt="img"/>
                            {item.contents && <p dangerouslySetInnerHTML={{__html:backHtmlspecialchars(item.contents)}}></p>}
                        </li>
                                )
                            } else if (item.type === "M") {
                                return(
                        <li key={i} className="img">
                            <video autoPlay muted={true}>
                            <source src={item.src} type="video/mp4"/>
                            </video>
                            {item.contents && <p dangerouslySetInnerHTML={{__html:backHtmlspecialchars(item.contents)}}></p>}
                        </li>
                                )
                            }
                        })}
                    </ul>
                </div>
            </div>
            
            <style jsx="true">{`
                @import '/css/gallery.css';
            `}</style>
        </div>
    )
}

export default TileType;