import { useEffect, useState } from "react";
import { QRCodeCanvas } from 'qrcode.react';
import { backHtmlspecialchars } from "../../data/func";
import { postContents } from "../axios/customAxios";
import ErrorPage from "../../pages/ErrorPage";

function QR({isLast=false, title, individualContentsSeqNo, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
        getQR();
    }, [individualContentsSeqNo]);

    const [url, setUrl] = useState(null);
    const [error, setError] = useState(null);
    const [contents, setContents] = useState(null);
    const [contents3, setContents3] = useState(null);
    function getQR() {
        postContents('/api/contents/individual/'+individualContentsSeqNo,{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null && res.response.detail != null) {
                const content = res.response.detail;
                setContents(content);
                setUrl(content.url);
                let contents3 = backHtmlspecialchars(content.contents3);
                if (contents3) {
                    contents3 = contents3.replace(/\r\n/ig, '<br>');
                    contents3 = contents3.replace(/\\n/ig, '<br>');
                    contents3 = contents3.replace(/\n/ig, '<br>');
                    if (contents3.indexOf("<br>") != -1) {
                        contents3 = contents3.replace(/\<br>/g, '<br/>');
                    }
                    let contents = contents3.split('<br/>');
                    setContents3(contents);
                } else {
                    setContents3(null);
                }
            } else {
                if (res?.error != null) {
                    setError(res?.error);
                }
                setContents(null);
            }

            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    if (contents != null) {
        return (
        // <div className="scene_qrcode animate__animated animate__bounceIn">
        <div className="scene_qrcode">
            <div className="qrcode">
                <p>QR코드</p>
                <div>
                    {url && 
                    <section>
                        <QRCodeCanvas 
                            fgColor="#393E46"
                            // size={550}
                            value={url}
                            style={{"width":"68%","height":"68%"}}
                        />
                    </section>
                    }
                </div>
                <p>QR코드 리더로 스캔해보세요.</p>
            </div>
            <div className="qrment">
                <p className="qrment_tit1">{contents.title}</p>
                <p className="qrment_tit2" dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents1)}}></p>
                <p className="qrment_used1" dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents2)}}></p>
                <ul className="qrment_used2" style={{whiteSpace:"pre-wrap"}}>
                    {contents3 
                        && contents3.map((item, i)=>{
                            return (<li key={i} dangerouslySetInnerHTML={{__html:item}}></li>)
                        })
                    }
                </ul>
            </div>
            <hr/>
        </div>
        )
    // } else {
    //     return (
    //         <ErrorPage title={"QR-"+title} error={error} />
    //     )
    }
}

export default QR;