import { Route, Routes } from "react-router-dom";
import { Error404 } from "../pages";
import LoginLayout from "../layout/LoginLayout";
import ConnectPage from "../pages/ConnectPage";
import LogoutProcess from "../pages/logoutProcess";

export default function LoginRoutes() {
    // console.log('loginroutes')
    return (
        <Routes>
            <Route path="/*" element={ <LoginLayout/> }>
                <Route path="connect" element={ <ConnectPage /> }/>
                <Route path="logout" element={ <LogoutProcess /> }/>
                <Route path="*" element={ <Error404/> }/>
            </Route>
        </Routes>
    );
}