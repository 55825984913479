import { Route, Routes } from 'react-router-dom';
import LoginRoutes from './routes/LoginRoutes';
import MainRoutes from './routes/MainRoutes';
import PrivateRoute from './components/route/PrivateRoute';
import Error403 from './pages/Error403';
import TestPage from './pages/TestPage';
import TestPage2 from './pages/TestPage2';

export default function RootRoutes() {
    // console.log('RootRoutes')
    return (
        <Routes>
            <Route element={<PrivateRoute auth={false} />}> {/* 로그인 하지 않아야 접근 가능 */}
                <Route path="test" element={ <TestPage /> }/>
                <Route path="test2" element={ <TestPage2 /> }/>
            {/* {console.log(111)} */}
                <Route path={"/auth/*"} element={<LoginRoutes />} errorElement={<Error403 />} />
            </Route>

            <Route element={<PrivateRoute auth={true} />}> {/* 로그인해야 접근 가능 */}
            {/* {console.log(222)} */}
                {/* <Route element={<AuthorityRoute pageRole={[ROLE.ALL.code]} />}> */}
                    <Route path="/*" element={ <MainRoutes /> }/>
                {/* </Route> */}
            </Route>
        </Routes>
    );
}