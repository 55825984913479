import { useEffect, useState } from "react";
import { backHtmlspecialchars } from "../../data/func";
import dayjs from "dayjs";
import { postContents } from "../axios/customAxios";
import ErrorPage from "../../pages/ErrorPage";

function DDay({isLast=false, title, individualContentsSeqNo, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
        getDday();
    }, [individualContentsSeqNo]);

    const today = dayjs().format('YYYY-MM-DD');
    const [duration, setDuration] = useState(null);
    const [giho, setGiho] = useState('-');

    const [error, setError] = useState(null);
    const [contents, setContents] = useState(null);
    function getDday() {
        postContents('/api/contents/individual/'+individualContentsSeqNo,{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null && res.response.detail != null) {
                setContents(res.response.detail);
                const dday = res.response.detail.contents2;
                // console.log(dday);
                if (dday && dday.split('-').length == 3) {
                    let diffday = dayjs(dday).diff(today, 'days');
                    // console.log(diffday);
                    // console.log(new String(diffday).length);
                    if (diffday < 0) {
                        setGiho("+");
                    }
                    let dday2 = new String(diffday);
                    dday2 = dday2.replace('-','');
                    setDuration(dday2);
                }
            } else {
                if (res?.error != null) {
                    setError(res?.error);
                }
                setContents(null);
            }

            if (res && res.error == null && res.alert != null) {
                if (res.alert.emergency) {
                    // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                    if (isEmergencyAlertFinish) {
                        setEmergencyContents(res.alert.emergency);
                        setIsEmergencyAlertFinish(false)
                    }
                }

                if (res.alert.general) {
                    // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                    if (isGeneralAlertFinish) {
                        setAlramContents(res.alert.general);
                        setIsGeneralAlertFinish(false)
                    }
                }
            }
        })
    }

    const rendering = () => {
        const result = [];
        // let dday = (new String(duration));
        for (let i = 0; i < duration.length; i++) {
          result.push(<li key={i}>{duration.substring(i, (i+1))}</li>);
        }
        return result;
    };

    if (contents != null) {
        return (
        // <div className="scene_day animate__animated animate__bounceIn">
        <div className="scene_day">
            <div>
                <div>
                    <p className="scene_day_tit" dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents1)}}></p>
                    <ul>
                        <li>D {giho}</li>
                        {duration && rendering()}
                    </ul>
                    <p className="scene_day_ment" dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents3)}}></p>
                </div>
            </div>
            <hr/>
        </div>
        )
    // } else {
    //     return (
    //         <ErrorPage title={"DDay-"+title} error={error} />
    //     )
    }
}

export default DDay;