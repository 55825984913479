import PhotoAlbum from "react-photo-album";

const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

// const unsplashLink = (id, width, height) => `https://source.unsplash.com/${id}/${width}x${height}`;

// function columns(containerWidth) {
//   let columns = 1;
//   if (containerWidth >= 500) columns = 2;
//   if (containerWidth >= 900) columns = 3;
//   if (containerWidth >= 1500) columns = 4;
//   return columns;
// }

const unsplashPhotos = [
    { id: "4", width: 1250, height: 721 , src: "https://schdev.didwalks.kr/upload/2024/05/08/m49cf4992c54b21dd854b58b382a92bb1.mp4", description:"dddd", ftype: "M", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    { id: "4", width: 1250, height: 721 , src: "https://schdev.didwalks.kr/upload/2024/04/03/bcdcc01e435b5c2ecd3d1d0a7e423697.jpg", description:"dddd", ftype: "I", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    { id: "5", width: 1250, height: 1520 , src: "https://schdev.didwalks.kr/upload/2024/04/03/caaeecfc88d7af7af9acec2f753a75a2.jpg", description:"eeee", ftype: "I", title : "222" },
    { id: "2", width: 1250, height: 608 , src: "https://schdev.didwalks.kr/upload/2024/03/21/c8b0681279f3a1a7c981fb673e9b23f5.jpeg", description:"bbbb", ftype: "I", title : "222", href:"http://www.naver.com" },
    { id: "1", width: 1250, height: 607 , src: "https://schdev.didwalks.kr/upload/2024/03/21/bb38c686e60598ccd98dd9bb94bb0172.jpg", description:"aaaaa", ftype: "I", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    { id: "4", width: 1250, height: 721 , src: "https://schdev-mng.didwalks.kr/upload/2024/04/26/mf0d5290d7a2627df0ae8ec6eae5a6d7b.mp4", description:"dddd", ftype: "M", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    { id: "3", width: 1250, height: 1549 , src: "https://schdev.didwalks.kr/upload/2024/04/24/3c40b3c9d2deec2efc90e75c2d35eb23.jpg", description:"cccc", ftype: "I", title : "333"},
    { id: "4", width: 1250, height: 721 , src: "https://schdev.didwalks.kr/upload/2024/04/03/bcdcc01e435b5c2ecd3d1d0a7e423697.jpg", description:"dddd", ftype: "I", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    { id: "5", width: 1250, height: 1520 , src: "https://schdev.didwalks.kr/upload/2024/04/03/caaeecfc88d7af7af9acec2f753a75a2.jpg", description:"eeee", ftype: "I", title : "222" },
    { id: "1", width: 1250, height: 607 , src: "https://schdev.didwalks.kr/upload/2024/03/21/bb38c686e60598ccd98dd9bb94bb0172.jpg", description:"aaaaa", ftype: "I", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    { id: "2", width: 1250, height: 608 , src: "https://schdev.didwalks.kr/upload/2024/03/21/c8b0681279f3a1a7c981fb673e9b23f5.jpeg", description:"bbbb", ftype: "I", title : "222", href:"http://www.naver.com" },
    { id: "3", width: 1250, height: 1549 , src: "https://schdev.didwalks.kr/upload/2024/04/24/3c40b3c9d2deec2efc90e75c2d35eb23.jpg", description:"cccc", ftype: "I", title : "333"},
    
    // { id: "1", width: 1080, height: 800 , src: "https://schdev.didwalks.kr/upload/2024/03/21/bb38c686e60598ccd98dd9bb94bb0172.jpg", description:"aaaaa", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    // { id: "2", width: 1080, height: 1620 , src: "https://schdev.didwalks.kr/upload/2024/03/21/c8b0681279f3a1a7c981fb673e9b23f5.jpeg", description:"bbbb", title : "222", href:"http://www.naver.com" },
    // { id: "3", width: 1080, height: 720 , src: "https://schdev.didwalks.kr/upload/2024/04/24/3c40b3c9d2deec2efc90e75c2d35eb23.jpg", description:"cccc", title : "333"},
    // { id: "4", width: 1080, height: 608 , src: "https://schdev.didwalks.kr/upload/2024/04/03/bcdcc01e435b5c2ecd3d1d0a7e423697.jpg", description:"dddd", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    // { id: "5", width: 1080, height: 1620 , src: "https://schdev.didwalks.kr/upload/2024/04/03/caaeecfc88d7af7af9acec2f753a75a2.jpg", description:"eeee", title : "222" },
    
    // { id: "1", width: "90%", height: "auto" , src: "https://schdev.didwalks.kr/upload/2024/03/21/bb38c686e60598ccd98dd9bb94bb0172.jpg", description:"aaaaa", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    // { id: "2", width: "90%", height: "auto" , src: "https://schdev.didwalks.kr/upload/2024/03/21/c8b0681279f3a1a7c981fb673e9b23f5.jpeg", description:"bbbb", title : "222", href:"http://www.naver.com" },
    // { id: "3", width: "90%", height: "auto" , src: "https://schdev.didwalks.kr/upload/2024/04/24/3c40b3c9d2deec2efc90e75c2d35eb23.jpg", description:"cccc", title : "333"},
    // { id: "4", width: "90%", height: "auto" , src: "https://schdev.didwalks.kr/upload/2024/04/03/bcdcc01e435b5c2ecd3d1d0a7e423697.jpg", description:"dddd", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    // { id: "5", width: "90%", height: "auto" , src: "https://schdev.didwalks.kr/upload/2024/04/03/caaeecfc88d7af7af9acec2f753a75a2.jpg", description:"eeee", title : "222" },
    
    // { id: "1", height: "100%", width: "auto" , src: "https://schdev.didwalks.kr/upload/2024/03/21/bb38c686e60598ccd98dd9bb94bb0172.jpg", description:"aaaaa", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    // { id: "2", height: "100%", width: "auto" , src: "https://schdev.didwalks.kr/upload/2024/03/21/c8b0681279f3a1a7c981fb673e9b23f5.jpeg", description:"bbbb", title : "222", href:"http://www.naver.com" },
    // { id: "3", height: "100%", width: "auto" , src: "https://schdev.didwalks.kr/upload/2024/04/24/3c40b3c9d2deec2efc90e75c2d35eb23.jpg", description:"cccc", title : "333"},
    // { id: "4", height: "100%", width: "auto" , src: "https://schdev.didwalks.kr/upload/2024/04/03/bcdcc01e435b5c2ecd3d1d0a7e423697.jpg", description:"dddd", title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
    // { id: "5", height: "100%", width: "auto" , src: "https://schdev.didwalks.kr/upload/2024/04/03/caaeecfc88d7af7af9acec2f753a75a2.jpg", description:"eeee", title : "222" },
    
    // { id: "6", width: 1080, height: 720 , src: "http://didmng.didwalks.kr:2000/upload/2024/05/02/8575b18048d6a27feed55106137e6e4f.jpg", title : "333"},
//   { id: "8gVv6nxq6gY", width: 1080, height: 800 , title : "대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’대한민국고등학교 학생 전시회 ‘우리들의 자서전’"},
//   { id: "Dhmn6ete6g8", width: 1080, height: 1620 , title : "222" },
//   { id: "RkBTPqPEGDo", width: 1080, height: 720 , title : "333"},
//   { id: "Yizrl9N_eDA", width: 1080, height: 721 , title : "444"},
//   { id: "KG3TyFi0iTU", width: 1080, height: 1620, title : "555" },
//   { id: "Jztmx9yqjBw", width: 1080, height: 607 , title : "666"},
//   { id: "-heLWtuAN3c", width: 1080, height: 608 , title : "777"},
//   { id: "xOigCUcFdA8", width: 1080, height: 720 , title : "888"},
//   { id: "1azAjl8FTnU", width: 1080, height: 1549, title : "999" },
//   { id: "ALrCdq-ui_Q", width: 1080, height: 720 , title : "000"},
//   { id: "twukN12EN7c", width: 1080, height: 694 },
//   { id: "9UjEyzA6pP4", width: 1080, height: 1620 },
//   { id: "sEXGgun3ZiE", width: 1080, height: 720 },
//   { id: "S-cdwrx-YuQ", width: 1080, height: 1440 },
//   { id: "q-motCAvPBM", width: 1080, height: 1620 },
//   { id: "Xn4L310ztMU", width: 1080, height: 810 },
//   { id: "iMchCC-3_fE", width: 1080, height: 610 },
//   { id: "X48pUOPKf7A", width: 1080, height: 160 },
//   { id: "GbLS6YVXj0U", width: 1080, height: 810 },
//   { id: "9CRd1J1rEOM", width: 1080, height: 720 },
//   { id: "xKhtkhc9HbQ", width: 1080, height: 1440 },
];

// eslint-disable-next-line
// var photos = unsplashPhotos.map((photo) => ({
// //   src: unsplashLink(photo.id, photo.width, photo.height),
//     src : photo.src,
//   width: photo.width,
//   height: photo.height,
//   title:photo.title,
//   srcSet: breakpoints.map((breakpoint) => {
//     const height = Math.round((photo.height / photo.width) * breakpoint);
//     return {
//     //   src: unsplashLink(photo.id, breakpoint, height),
//     src : photo.src,
//       width: breakpoint,
//       height,
//     };
//   }),
// }));

// const renderContainer = ({ containerProps, children, containerRef }) => (
//     <div
//       style={{
//         border: "2px solid #eee",
//         borderRadius: "10px",
//         padding: "20px",
//       }}
//     >
//       <div ref={containerRef} {...containerProps}>
//         {children}
//       </div>
//     </div>
//   );
  
//   const renderRowContainer = ({ rowContainerProps, rowIndex, rowsCount, children }) => (
//     <>
//       <div {...rowContainerProps}>{children}</div>
//       {rowIndex < rowsCount - 1 && (
//         <div
//           style={{
//             borderTop: "2px solid #eee",
//             marginBottom: "-20px",
//           }}
//         />
//       )}
//     </>
//   );
  
const renderPhoto = ({ layout, layoutOptions, imageProps: { alt, style, description, ftype, ...restImageProps } }) => (
  <div
    className="gallery_box"
    style={{
      width: style?.width,
      paddingBottom: 0,
    }}
  >
    {restImageProps.src.indexOf('.mp4') !== -1 ? 
    <video id="vd" autoPlay muted={true} style={{width:"100%",height:"100%"}}>
      Your browser does not support the video tag.
      <source src={`${restImageProps.src}`} type="video/mp4" />
    </video>
    :
    <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
    }
    <div
      className="gallery_cont"
    >{restImageProps.description}////{restImageProps.ftype}
      <p>.{restImageProps.type}이미지 설명...이미지 설명...이미지 설명...이미지 설명...이미지 설명...이미지 설명.....</p>
      {/* {Math.round(layout.width) + " x " + Math.round(layout.height)} */}
    </div>
  </div>
);

export default function App() {
  console.log(unsplashPhotos)
    return (
        <>
          <div className="scene_gallery_columns">
              <p className="gallery_tit">대한민국고등학교 학생 전시회 ‘우리들의 자서전’</p>
              <PhotoAlbum layout="columns" photos={unsplashPhotos} 
                  renderPhoto={renderPhoto}
                columns={(containerWidth) => {
                  if (containerWidth < 1920) return 4;
                  return 4;
              }}/>
              {/* <video>
                <source src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"></source>
              </video> */}
          </div>
      </>
    );
}