import React, { useContext } from 'react';
import { ModalsDispatchContext, ModalsStateContext } from './ModalsContext';
import loadable from '@loadable/component';

export const modals = {
    alertModal : loadable(() => import('./AlertModal')),
    confirmModal : loadable(() => import('./ConfirmModal')),
    promptModal : loadable(() => import('./PromptModal')),
};

const Modals = () => {
  const openedModals = useContext(ModalsStateContext);
  const { close } = useContext(ModalsDispatchContext);

  return openedModals.map((modal, index) => {
    const { Component, props } = modal;
    const { onSubmit, onClose, ...restProps } = props;

    const handleClose = (value) => {
      if (typeof onClose === 'function') {
        onClose(value);
      }
      close(Component);
    };
    
    const handleSubmit = (value) => {
        if (typeof onSubmit === 'function') {
           onSubmit(value);
        }
        handleClose();
      };
      
      return (
        <Component
        {...restProps}
          key={index}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      );
  });
};

export default Modals;