import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { postContents } from "../axios/customAxios";
import TodayComponent from "../TodayComponent";
import { getFineDustGrade, getWeatherIcon } from "../../data/func";

function SchoolNotice ({isLast=false, category, title, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    const schoolInfo = useSelector(state => state.sch);
    useEffect(()=>{
        getSchoolNoticeList();
    }, [category]);

    const [dataList, setDataList] = useState(null);
    const [crawlingDate, setCrawlingDate] = useState(null);
    const [weatherIcon, setWeatherIcon] = useState(null);
    const [basicWeather, setBasicWeather] = useState({});
    const [className, setClassName] = useState('scene_notice');
    function getSchoolNoticeList() {
        let apiUrl = '/api/contents/school/notice';
        if (category === 'NT') {
            apiUrl = '/api/contents/school/notice';
            //setClassName('scene_notice animate__animated animate__bounceIn');
            setClassName('scene_notice');
        } else if (category === 'FC') {
            apiUrl = '/api/contents/school/homeLetter';
            //setClassName('scene_notice animate__animated animate__bounceIn scene_newsletter');
            setClassName('scene_notice scene_newsletter');
        }

        Promise.all([
            postContents('/api/contents/weather', {isAlert:false}).then((res)=>{
                // console.log(res);
                if (res && res.error == null && res.response != null) {
                    let basic = res.response.basicWeather;
                    // console.log(basic)
                    setBasicWeather(basic);
                    if (basic != null) {
                        setWeatherIcon(getWeatherIcon(basic.skyStatus, basic.precipitationForm))
                    } else {
                        setWeatherIcon("Sunny");
                    }
                } else {
                    setBasicWeather({})
                }
            }),
            postContents(apiUrl,{isAlert:!isLast}).then((res)=>{
                // console.log(res);
                if (res && res.error == null && res.response != null) {
                    setDataList(res.response.list);
                    setCrawlingDate(res.response.crawlingDate);
                } else {
                    setDataList(null)
                    setCrawlingDate(null)
                }

                if (res && res.error == null && res.alert != null) {
                    if (res.alert.emergency) {
                        // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                        if (isEmergencyAlertFinish) {
                            setEmergencyContents(res.alert.emergency);
                            setIsEmergencyAlertFinish(false)
                        }
                    }

                    if (res.alert.general) {
                        // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                        if (isGeneralAlertFinish) {
                            setAlramContents(res.alert.general);
                            setIsGeneralAlertFinish(false)
                        }
                    }
                }
            })
        ]).then(()=>{

        })
    }

    return (
        <div className={className}>
            <div>
                <div className="topbar">
                    <TodayComponent />
                    <hr/>
                    {basicWeather && 
                    <>
                    <div className="weather">
                        <span className="material-symbols-outlined">{weatherIcon}</span>
                        <p>{basicWeather && basicWeather.temperature}<span>℃</span></p>
                    </div>
                    <hr/>

                    <div className="dust">
                        {basicWeather && basicWeather.fineDustGrade ?
                        <img src={`${process.env.PUBLIC_URL}/images/dust_${basicWeather && basicWeather.fineDustGrade}.png`} alt="미세먼지 아이콘" />
                        :
                        <img src={`${process.env.PUBLIC_URL}/images/dust_0.png`} alt="미세먼지 아이콘" />
                        }

                        <div>
                            <p>미세먼지</p>
                            <p>{getFineDustGrade(basicWeather && basicWeather.fineDustGrade)}</p>
                        </div>
                    </div>
                    <hr/>
                    </>
                    }
                    <div className="school">
                        <p>배움이 즐거운 행복한 학교</p>
                        <p>{schoolInfo.name}</p>
                    </div>
                    <hr/>
                </div>

                <div className="notice_asset">
                    <h1>{title}</h1>
                    <ul>
                        {dataList && dataList.map((item, idx)=>{
                            if (idx < 7) {
                            return (
                        <li key={idx}><p style={{overflow: "hidden", width: "82%", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>{item.bbsTitle}</p><span>[{dayjs(item.bbsCreatedDate).format('YYYY-MM-DD')}]</span></li>
                            )
                            }
                        })}
                    </ul>
                </div>
            </div>
            <hr/>
        </div>
    )
}

export default SchoolNotice;