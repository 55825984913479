import React, { useEffect, useState } from 'react';

import SchoolNotice from '../components/crawling_api/SchoolNotice';
import SchoolSchedule from '../components/crawling_api/SchoolSchedule';
import SchoolMeal from '../components/crawling_api/SchoolMeal';
import Weather from '../components/crawling_api/Weather';
import Info from '../components/school/Info';
import Image from '../components/school/Image';
import FamousPainting from '../components/common/FamousPainting';
// import Movie from '../components/school/Movie';
import QR from '../components/school/QR';
import TodayEnglish from '../components/common/TodayEnglish';
import WiseSaying from '../components/common/WiseSaying';
import DDay from '../components/school/DDay';
import ImageFull from '../components/school/ImageFull';
import { useTitle } from '../data/func';
import axios from 'axios';
import { postLastConnectionLog } from '../components/axios/customAxios';
import MovieDesc from '../components/school/MovieDesc';
import WeatherInfo from '../components/school/WeatherInfo';
import BoardType from '../components/file_box/BoardType';
import TileType from '../components/file_box/TileType';
// import EmergencyAlert from '../components/alert/EmergencyAlert';
// import GeneralAlert from '../components/alert/GeneralAlert';

const SplitScreen1 = ({schedule, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) => {
  const changeTitle = useTitle();
  const [lastConntectInfo, setLastConnectionInfo] = useState({});

  useEffect(()=>{
    changeTitle(schedule.title);

    Promise.all([
        getGeolocation()
    ]).then(()=>{
        // console.log(lastConntectInfo)
        let lastConntectInfoCopy = {...lastConntectInfo};
        // lastConntectInfoCopy.userAgent = window.navigator.userAgent;
        lastConntectInfoCopy.seqNo = schedule.seqNo;
        lastConntectInfoCopy.ver = process.env.REACT_APP_SOURCE_VERSION;
        console.log(lastConntectInfoCopy)
        setLastConnectionInfo(lastConntectInfoCopy);
        // console.log(lastConntectInfoCopy)
    
        postLastConnectionLog(lastConntectInfoCopy);
    })
    
  },[schedule])

  const getGeolocation = async() => {
    // console.log(navigator.geolocation)
    try {
      await axios.get('https://geolocation-db.com/json/').then((res) => {
          // console.log(res.data)
          let lastConntectInfoCopy = {...lastConntectInfo};
          // lastConntectInfoCopy.connectionDate = dayjs(new Date()).format();
          lastConntectInfoCopy.geolocation    = JSON.stringify(res.data);
        //   lastConntectInfoCopy.ip             = res.data && res.data.IPv4;
          setLastConnectionInfo(lastConntectInfoCopy);
        //   console.log(lastConntectInfoCopy)
          
      }).catch((err)=>{
          console.log(err);
      })
    } catch(err) {
        console.log(err);
    }
  }

  if (schedule != null) {
    return(
        schedule.contentsList.map((content, idx) => {
            if (content.category === 'NT' || content.category === 'FC') {
                return (<SchoolNotice key={idx} isLast={schedule.isLast} category={content.category} title={schedule.title} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'CT') {
                return (<SchoolMeal key={idx} isLast={schedule.isLast} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'SC') {
                return (<SchoolSchedule key={idx} isLast={schedule.isLast} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'WT') {
                return (<Weather key={idx} isLast={schedule.isLast} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            // } else if (content.category === 'VD') {
            //     return (<Movie key={idx} isLast={schedule.isLast} title={schedule.title} individualContentsSeqNo={content.individualContents.seqNo} 
            //         setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
            //         setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
            //         setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'VM') {
                return (<MovieDesc key={idx} isLast={schedule.isLast} title={schedule.title} individualContentsSeqNo={content.individualContents.seqNo} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'QR') {
                return (<QR key={idx} isLast={schedule.isLast} title={schedule.title} individualContentsSeqNo={content.individualContents.seqNo} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'IM') {
                return (<Image key={idx} isLast={schedule.isLast} title={schedule.title} individualContentsSeqNo={content.individualContents.seqNo} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'IF') {
                return (<Info key={idx} isLast={schedule.isLast} title={schedule.title} individualContentsSeqNo={content.individualContents.seqNo} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'DD') {
                return (<DDay key={idx} isLast={schedule.isLast} title={schedule.title} individualContentsSeqNo={content.individualContents.seqNo} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'IT') {
                return (<ImageFull key={idx} isLast={schedule.isLast} title={schedule.title} individualContentsSeqNo={content.individualContents.seqNo} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'WI') {
                return (<WeatherInfo key={idx} isLast={schedule.isLast} title={schedule.title} individualContentsSeqNo={content.individualContents.seqNo} 
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'FP') {
                return (<FamousPainting key={idx} isLast={schedule.isLast} title={schedule.title} schoolCommonContentsSeqNo={content.schoolCommonContents?.seqNo}
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'FS') {
                return (<WiseSaying key={idx} isLast={schedule.isLast} title={schedule.title} schoolCommonContentsSeqNo={content.schoolCommonContents?.seqNo}
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'WP') {
                return (<TodayEnglish key={idx} isLast={schedule.isLast} title={schedule.title} schoolCommonContentsSeqNo={content.schoolCommonContents?.seqNo}
                    setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                    setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                    setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
            } else if (content.category === 'F1' || content.category === 'F2') {
                let fileNos = content.fileBoxContentsList.map((item)=>item.seqNo);
                    // console.log(fileNos)
                if (content.category === 'F1') {
                    return (<TileType key={idx} isLast={schedule.isLast} title={schedule.title} fileContentsSeqNos={fileNos}
                        setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                        setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                        setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
                } else if (content.category === 'F2') {
                    return (<BoardType key={idx} isLast={schedule.isLast} title={schedule.title} fileContentsSeqNos={fileNos}
                        setEmergencyContents={setEmergencyContents} setAlramContents={setAlramContents} 
                        setIsEmergencyAlertFinish={setIsEmergencyAlertFinish} isEmergencyAlertFinish={isEmergencyAlertFinish}
                        setIsGeneralAlertFinish={setIsGeneralAlertFinish} isGeneralAlertFinish={isGeneralAlertFinish} />)
                }
            // } else if (content.category === 'EG') {
            //     return (<EmergencyAlert key={idx} title={schedule.title} emergencyContents={emergencyContents} />)
            // }  else if (content.category === 'GN') {
            //     return (<GeneralAlert key={idx} title={schedule.title} />)
            }
        })
        // <GeneralAlert alramContents={{title: "Test", contents:"testtest122324234 testtest122324234 testtest122324234"}} />
        // <EmergencyAlert emergencyContents={{title: "Test", contents: `testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234testtest122324234testtest122324234testtest122324234
        // testtest122324234
        // testtest122324234
        // testtest122324234`}} />
    )
  }
}

export default SplitScreen1;