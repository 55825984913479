import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import React, { useState } from 'react'

function TodayComponent () {
  dayjs.locale('ko');

  const [date, setDate] = useState(dayjs(new Date()).format('YYYY년 MM월 DD일 ddd요일'));
  const [timer, setTimer] = useState(dayjs(new Date()).format('A hh:mm'));

  const currentTimer = () => {
    setDate(dayjs(new Date()).format('YYYY년 MM월 DD일 ddd요일'));
    setTimer(dayjs(new Date()).format('A hh:mm'));
  }

  const startTimer = () => {
    setInterval(currentTimer, 1000)
  }

  startTimer()

  return (
    <div className="today_date">
      <p>{date}</p>
      <p>{timer}</p>
    </div>
  )
}

export default TodayComponent;