import { Navigate, Outlet, useLocation } from "react-router-dom"
import { useSelector } from "react-redux";
import { loginInterceptor, setClientHeaders } from "../axios/customAxios";

function PrivateRoute ({auth}) {
  // console.log(auth)
  const { authenticated, accessToken } = useSelector(state => state.authToken);
  // console.log(authenticated)
  const fullpath = useLocation().pathname;
  // console.log(fullpath)
  const token = localStorage.getItem("persist:root") && JSON.parse(localStorage.getItem("persist:root")).authToken && JSON.parse(JSON.parse(localStorage.getItem("persist:root")).authToken).accessToken; 
  // console.log(token)
  
  loginInterceptor();

  if (authenticated) {
    const authToken = (accessToken || token);
    // console.log(authToken);
    // setClientHeaders(authToken);
    if (fullpath == '/auth/connect' || fullpath == '/') {
      return <Navigate to={"/index"} />
    } else {
      setClientHeaders(authToken);
      return <Outlet />;
    }
  } if (!authenticated && auth) {
    return <Navigate to={"/auth/connect"} />
  } if (!authenticated && !auth) {
    return <Outlet />
  }

  // if (authenticated && auth) {
  //   if (fullpath == '/auth/connect') {
  //     return <Navigate to={"/index"} />
  //   } else {
  //     return <Outlet />;
  //   }
  // } if (!authenticated && !auth) {
  //   return <Outlet />
  // } else {
  //   return <Navigate to={"/auth/connect"} />
  // }
}

export default PrivateRoute