import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import React, { useState } from 'react'

function TodayComponentWI () {
  dayjs.locale('ko');

  const [date, setDate] = useState(dayjs(new Date()).format('MM월 DD일 (ddd)'));
  const [timer, setTimer] = useState(dayjs(new Date()).format('hh:mm A'));

  const currentTimer = () => {
    setDate(dayjs(new Date()).format('MM월 DD일 (ddd)'));
    setTimer(dayjs(new Date()).format('hh:mm A'));
  }

  const startTimer = () => {
    setInterval(currentTimer, 1000)
  }

  startTimer()

  return (
    <div className="weather_time">
        <p>{date}</p>
        <p>{timer}</p>
    </div>
  )
}

export default TodayComponentWI;