import { useEffect, useRef, useState } from "react";
import { backHtmlspecialchars } from "../../data/func";

function EmergencyAlert({emergencyContents}) {
    useEffect(()=>{
        emergency();
    }, []);
    
    const [title, setTitle] = useState(null);
    const [contents, setContents] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [count, setCount] = useState(0);
    const containerRef = useRef(null);
    const textRef = useRef(null);

    function emergency() {
//         const alert = {title : "재량휴업일 안내", contents : `1. 학교장 재량휴업일 : 2023. 01. 22.(월) [1일간]
// 2. 대상 : 유치원 및 전교생
// 3. 연락처 : 교무실 (***) *** - ****
// 4. 학교장 재량휴업일에 초등긴급돌봄교실은 신청한 학생에 한하여 운영하며, 통학차량을 운행하지 않으므로 보호자가 등• 하교를 시켜주셔야 합니다.
// * 유치원 긴급돌봄교실은 운영하지 않습니다.1
// * 유치원 긴급돌봄교실은 운영하지 않습니다.2
// * 유치원 긴급돌봄교실은 운영하지 않습니다.3
// * 유치원 긴급돌봄교실은 운영하지 않습니다.4
// * 유치원 긴급돌봄교실은 운영하지 않습니다.5
// * 유치원 긴급돌봄교실은 운영하지 않습니다.6
// * 유치원 긴급돌봄교실은 운영하지 않습니다.7
// * 유치원 긴급돌봄교실은 운영하지 않습니다.8
// * 유치원 긴급돌봄교실은 운영하지 않습니다.9
// * 유치원 긴급돌봄교실은 운영하지 않습니다.10
// * 유치원 긴급돌봄교실은 운영하지 않습니다.11
// * 유치원 긴급돌봄교실은 운영하지 않습니다.12
// * 유치원 긴급돌봄교실은 운영하지 않습니다.13
// * 유치원 긴급돌봄교실은 운영하지 않습니다.14
// * 유치원 긴급돌봄교실은 운영하지 않습니다.15
// * 유치원 긴급돌봄교실은 운영하지 않습니다.16
// * 유치원 긴급돌봄교실은 운영하지 않습니다.17
// `
// ,emergencyNotificationTime: 15, emergencyNotificationRepeat: 3};
        const alert = emergencyContents;

        console.log(alert);
        setTitle(alert.title);
        let contents = backHtmlspecialchars(alert.contents);
        console.log(contents);
        contents = contents.replace(/\r\n/ig, '<br>');
        contents = contents.replace(/\\n/ig, '<br>');
        contents = contents.replace(/\n/ig, '<br>');
        if (contents.indexOf("<br>") !== -1) {
            contents = contents.replace(/\<br>/g, '<br/>');
        }
        setContents(contents.split('<br/>'))
        setIsLoading(true);
    }

    useEffect(()=>{
        // console.log(count)
        if (isLoading) {
            if (containerRef.current?.offsetHeight > textRef.current?.offsetHeight) {
                textRef.current?.style.setProperty('animation','none');
                textRef.current?.style.setProperty('top','0');
            } else {
                textRef.current?.style.setProperty('animation','scrollText 50s linear infinite');
            }
        }
    },[isLoading]);

    if (title != null && contents != null) {
        return (
        <div className="scene_alram">
            <div>
                <span id="alram_title">{title}</span>
                <div id="emergency_alert_contbox" ref={containerRef}>
                    <div id="autoscroll" ref={textRef} style={{whiteSpace:"pre-wrap"}}>
                    {contents 
                        && contents.map((item, i)=>{
                            return (<div key={i} dangerouslySetInnerHTML={{__html:item}}></div>)
                        })
                    }
                    </div>
                </div>
            </div>
            <hr/>
        </div>
        )
    }
}

export default EmergencyAlert;