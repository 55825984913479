import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { postContents } from "../axios/customAxios";
import { backHtmlspecialchars } from "../../data/func";
import TodayComponent from "../TodayComponent";
import { getFineDustGrade, getWeatherIcon } from "../../data/func";

function BoardType({isLast=false, title, fileContentsSeqNos, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    const schoolInfo = useSelector(state => state.sch);
    useEffect(()=>{
		getBoardType()
    }, []);

    const [error, setError] = useState(null);
    const [photos, setPhotos] = useState();
    const [weatherIcon, setWeatherIcon] = useState(null);
    const [basicWeather, setBasicWeather] = useState({});
    function getBoardType() {
        let params = {
            seqNos : fileContentsSeqNos,
            isAlert:!isLast
        }

        Promise.all([
            postContents('/api/contents/weather', {isAlert:false}).then((res)=>{
                // console.log(res);
                if (res && res.error == null && res.response != null) {
                    let basic = res.response.basicWeather;
                    // console.log(basic)
                    setBasicWeather(basic);
                    if (basic != null) {
                        setWeatherIcon(getWeatherIcon(basic.skyStatus, basic.precipitationForm))
                    } else {
                        setWeatherIcon("Sunny");
                    }
                } else {
                    setBasicWeather({})
                }
            }),
            postContents('/api/contents/fileBox',params).then((res)=>{
                // console.log(res);
                if (res && res.error == null && res.response != null && res.response.detail != null) {
                    const contents = res.response.detail;
                    // setContents(content);
                    if (contents) {
                        // document.body.classList.add("gallery");
                        // var imgObj = document.getElementById('uploadedImage');
                        let photos = contents.map((item) => {
                            // let newImg = new Image();
                            // newImg.src = process.env.REACT_APP_UPLOAD+item.file.url;
                            // console.log(newImg);
                            // console.log(newImg.naturalWidth);
                            // console.log(newImg.naturalHeight);

                            // return {id:item.seqNo, src:process.env.REACT_APP_UPLOAD+item.file.url, width:1200, Height:500, contents:item.contents, type:item.file.type}
                            return {id:item.seqNo, src:process.env.REACT_APP_UPLOAD+item.file.url, contents:item.contents, type:item.file.type}
                        })
                        // console.log(photos)
                        setPhotos(photos);
                    } else {
                        setPhotos(null);
                    }
                } else {
                    if (res?.error != null) {
                        setError(res?.error);
                    }
                    setPhotos(null);
                }

                if (res && res.error == null && res.alert != null) {
                    if (res.alert.emergency) {
                        // console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
                        if (isEmergencyAlertFinish) {
                            setEmergencyContents(res.alert.emergency);
                            setIsEmergencyAlertFinish(false)
                        }
                    }

                    if (res.alert.general) {
                        // console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
                        if (isGeneralAlertFinish) {
                            setAlramContents(res.alert.general);
                            setIsGeneralAlertFinish(false)
                        }
                    }
                }
            })
        ]).then(()=>{

        })
    }

    return (
        <div className="scene_popupgallery">
            <div>
                <div className="topbar">
                    <TodayComponent />
                    <hr/>
                    {basicWeather && 
                    <>
                    <div className="weather">
                        <span className="material-symbols-outlined">{weatherIcon}</span>
                        <p>{basicWeather && basicWeather.temperature}<span>℃</span></p>
                    </div>
                    <hr/>

                    <div className="dust">
                        {basicWeather && basicWeather.fineDustGrade ?
                        <img src={`${process.env.PUBLIC_URL}/images/dust_${basicWeather && basicWeather.fineDustGrade}.png`} alt="미세먼지 아이콘" />
                        :
                        <img src={`${process.env.PUBLIC_URL}/images/dust_0.png`} alt="미세먼지 아이콘" />
                        }

                        <div>
                            <p>미세먼지</p>
                            <p>{getFineDustGrade(basicWeather && basicWeather.fineDustGrade)}</p>
                        </div>
                    </div>
                    <hr/>
                    </>
                    }

                    <div className="school">
                        <p>배움이 즐거운 행복한 학교</p>
                        <p>{schoolInfo.name}</p>
                    </div>
                    <hr/>
                </div>

                <div className="popupgallery_cont">
                    <p className="popupgallery_tit"><span dangerouslySetInnerHTML={{__html:backHtmlspecialchars(title)}}></span></p>
                    <ul>
                        {photos && photos.map((item, i)=> {
                            if (item.type === "I") {
                                return (
                        <li key={i}>
                            <img src={item.src} alt="img"/>
                            {item.contents && <p><span dangerouslySetInnerHTML={{__html:backHtmlspecialchars(item.contents)}}></span></p>}
                        </li>
                                )
                            } else if (item.type === "M") {
                                return(
                        <li key={i}>
                            <video autoPlay muted={true}>
                            <source src={item.src} type="video/mp4"/>
                            </video>
                            {item.contents && <p><span dangerouslySetInnerHTML={{__html:backHtmlspecialchars(item.contents)}}></span></p>}
                        </li>
                                )
                            }
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default BoardType;