import { useEffect, useState } from "react";
import { backHtmlspecialchars } from "../../data/func";
import { postContents } from "../axios/customAxios";
import ErrorPage from "../../pages/ErrorPage";


function TodayEnglish({isLast=false, title, schoolCommonContentsSeqNo, setEmergencyContents, setAlramContents, setIsEmergencyAlertFinish, isEmergencyAlertFinish, setIsGeneralAlertFinish, isGeneralAlertFinish}) {
    useEffect(()=>{
		getTodayEnglish()
    }, []);

	const [error, setError] = useState(null);
    const [contents, setContents] = useState(null);
	const [contents3, setContents3] = useState(null);
	function getTodayEnglish() {
        postContents('/api/contents/school/common/WP/'+schoolCommonContentsSeqNo,{isAlert:!isLast}).then((res)=>{
		// postContents('/api/contents/school/common/WP',{isAlert:!isLast}).then((res)=>{
            // console.log(res);
            if (res && res.error == null && res.response != null && res.response.detail != null) {
				let content =res.response.detail;
                setContents(content);
				let contents3 = backHtmlspecialchars(content.contents3);
				if (contents3) {
					contents3 = contents3.replace(/\r\n/ig, '<br>');
					contents3 = contents3.replace(/\\n/ig, '<br>');
					contents3 = contents3.replace(/\n/ig, '<br>');
					if (contents3.indexOf("<br>") != -1) {
						contents3 = contents3.replace(/\<br>/g, '<br/>');
					}
					let contents = contents3.split('<br/>');
					setContents3(contents);
				} else {
					setContents3(null);
				}
            } else {
                if (res?.error != null) {
                    setError(res?.error);
                }
                setContents(null);
            }

			if (res && res.error == null && res.alert != null) {
				if (res.alert.emergency) {
					// console.log("isEmergencyAlertFinish : " + isEmergencyAlertFinish)
					if (isEmergencyAlertFinish) {
						setEmergencyContents(res.alert.emergency);
						setIsEmergencyAlertFinish(false)
					}
				}

				if (res.alert.general) {
					// console.log("isGeneralAlertFinish : " + isGeneralAlertFinish)
					if (isGeneralAlertFinish) {
						setAlramContents(res.alert.general);
						setIsGeneralAlertFinish(false)
					}
				}
			}
        })
    }

    if (contents != null) {
        return (
        // <div className="scene_engwidiom animate__animated animate__bounceIn">
		<div className="scene_engwidiom">
			<div>
				<div>
					<div>
						<p>{contents.title}</p>
						<p dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents1)}}></p>
					</div>
					<ul>
						<li dangerouslySetInnerHTML={{__html:backHtmlspecialchars(contents.contents2)}}></li>
						<li>
							{contents3 
								&& contents3.map((item, i)=>{
									item = item.replace(' '+contents.title, ' <span>'+contents.title+'</span>');
									return (<p key={i} dangerouslySetInnerHTML={{__html:item}}></p>)
								})
							}
						</li>
					</ul>
				</div>
			</div>
			<hr/>
		</div>
		)
	} else {
		if (error) {
			return (
			<ErrorPage title={"영어휘-"+title} error={error} />
			)
		}
	}
}

export default TodayEnglish;